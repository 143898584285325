import {
    VALIDATE_ALPHANUMERIC_SPACE_UNDERSCORE,
    VALIDATE_STRING_LONG,
    VALIDATE_STRING_SHORT
} from "../../../../util/FormFunctions";

export const VALIDATE_NAME = (name) => {
    return VALIDATE_ALPHANUMERIC_SPACE_UNDERSCORE(name)
}

export const VALIDATE_NAME_SHORT = (name) => {
    return VALIDATE_STRING_SHORT(name, 3)
}

export const VALIDATE_NAME_LONG = (name) => {
    return VALIDATE_STRING_LONG(name, 20)
}