import SuperLink from "../component/SuperLink/SuperLink";
import {Trans} from "react-i18next";
import React from "react";

export const HOME_SUPER_LINKS = [
    <SuperLink
        link={'/tool/french-mortgage-calculator'}
        title={<Trans i18nKey={'tool_mortgage_calculator'}/>}
        color={'#5e4913'}
        highlightedColor={'#4b3d10'}
        opacity={0.9}
    />,
    <SuperLink
        link={'/tool/french-mortgage-comparator'}
        title={<Trans i18nKey={'tool_mortgage_comparator'}/>}
        color={'#5e4913'}
        highlightedColor={'#4b3d10'}
        opacity={0.9}
    />
]