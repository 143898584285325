import React from 'react';
import {sliderModifier} from "../../util/SliderModifier";
import {Box, FormControl, FormHelperText, Typography} from "@mui/material";
import Grid from "@mui/material/Grid2";
import Slider from "@mui/material/Slider";

/**
 * Creates a common double slider component.
 *
 * @param {Object} options - The options for the double slider.
 * @param {string} options.title - The title of the double slider.
 * @param {array} options.value - The current values of the double slider.
 * @param {function} options.onChange - The function to be called when the double slider value changes.
 * @param {boolean} [options.disabled=false] - Indicates whether the double slider is disabled.
 * @param {number} [options.min=0] - The minimum value of the double slider.
 * @param {number} options.max - The maximum value of the double slider.
 * @param {number} [options.step=1] - The step size of the double slider.
 *
 * @return {ReactElement} The double slider component.
 */
export default function CommonDoubleSlider(
    {
        title, value, onChange, helpText, disabled = false, min = 0, max, step = 1
    }
) {

    return (
        <Box sx={{...sliderModifier, height: 'fit-content'}}>
            <FormControl fullWidth>
                <Box className="composed-slider"
                     sx={{
                         height: {xs: 'fit-content !important', mi: 'fit-content !important'},
                         padding: {xs: '0 1.5em !important', mi: '0 1em !important'}
                     }}>
                    <Typography gutterBottom variant={"body2"}
                                sx={{
                                    color: "#fff",
                                    fontSize: {xs: '1.2em !important', mi: '0.7em !important'},
                                    marginBottom: {xs: '0.8em !important', mi: '0.5em !important'},
                                }}>
                        {title}
                    </Typography>
                    <Grid container columns={20} spacing={1} alignItems={'center'}>
                        <Grid item size={1} sx={{height: '100%', textAlign: 'center'}}>
                            <Typography className={"value-slider"}
                                        sx={{fontSize: {xs: '2em !important', mi: '1em !important'}}}>
                                {parseFloat(value[0]) === 0 ? value[0] + '.0' : value[0]}
                            </Typography>
                        </Grid>
                        <Grid item size='grow'>
                            <Slider
                                className={"slider"}
                                disabled={disabled}
                                value={value}
                                onChange={(event) => onChange(event)}
                                aria-describedby={`${value}_help`}
                                min={min}
                                max={max}
                                step={step}
                                sx={{
                                    color: '#ffffff',
                                    top: "-0.7em"
                                }}
                            />
                        </Grid>
                        <Grid item size={1}>
                            <Typography className={"value-slider"}
                                        sx={{fontSize: {xs: '2em !important', mi: '1em !important'}}}>
                                {value[1]}
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
                {helpText &&
                    <FormHelperText id={`${value}_help`}
                                    style={{width: '100%', color: 'grey', margin: '0', padding: '0 0 0 1em'}}>
                        {helpText}
                    </FormHelperText>}
            </FormControl>
        </Box>
    );
}