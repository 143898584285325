import {Trans} from "react-i18next";

/**
 * Handles the change of the maximum decimal value entered by the user.
 *
 * @param {Object} event - The event object representing the change event.
 * @param {function} setValueFunc - The function to set the new value.
 * @param {number} defaultValue - The default value to be set if input is not a number.
 * @param {number} [maxDecimals=2] - The maximum number of decimal places allowed.
 *
 * @returns {void}
 */
export const handleMaxDecimalsOnInputChange = (event, setValueFunc, defaultValue, maxDecimals = 2) => {
    let input = parseFloat(event.target.value);
    if (input && !isNaN(input)) {
        let decimals = (event.target.value.split('.')[1] || []).length;
        if ((decimals | 0) <= maxDecimals) {
            setValueFunc(event.target.value);
        }
    } else {
        setValueFunc(defaultValue);
    }
};

/**
 * Handles the onBlur event for an input field and ensures that the value has a minimum number of decimals.
 * If the input value has a decimal part that has less than or equal to the maximum number of decimals specified, it sets the formatted value using the setValueFunc.
 *
 * @param {Event} event - The onBlur event object.
 * @param {Function} setValueFunc - The function to set the formatted value.
 * @param {number} defaultValue - The default value to be set if input is not a number.
 * @param {number} maxDecimals - The maximum number of decimal places allowed (default is 2).
 */
export const handleMinimumDecimalsOnBlur = (event, setValueFunc, defaultValue, maxDecimals = 2) => {
    let input = parseFloat(event.target.value);
    if (input && !isNaN(input)) {
        setValueFunc(input.toFixed(maxDecimals).toString());
    } else {
        setValueFunc(defaultValue);
    }
};


/**
 * Checks if a given object is empty.
 *
 * @param {*} obj - The object to be checked.
 * @returns {boolean} - True if the object is empty, false otherwise.
 */
export const isEmpty = obj => obj === undefined || obj === null || obj === '';

export const isNotEmpty = obj => !isEmpty(obj);

// Form Validators

export function VALIDATE_STRING_SHORT(name, length) {
    return name.length < length
        ? (<Trans i18nKey={"validation_string_too_short"} values={{length: length}}/>)
        : undefined;
}

export function VALIDATE_STRING_LONG (name, length) {
    return name.length > length
        ? (<Trans i18nKey={"validation_string_too_long"} values={{length: length}}/>)
        : undefined;
}

export function VALIDATE_NUMBER (value, low, high) {
    return value < low || value > high
        ? (<Trans i18nKey={"validation_number_length"} values={{low: low, high: high}}/>)
        : undefined;
}


export function VALIDATE_ALPHANUMERIC_SPACE_UNDERSCORE(value) {
    const regex = /^[a-zA-Z0-9 _]+$/;
    return regex.test(value)
        ? undefined
        : (<Trans i18nKey={"validation_alphanumeric_space_underscore"}/>);
}
