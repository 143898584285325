import {VALIDATE_NUMBER, VALIDATE_STRING_LONG, VALIDATE_STRING_SHORT} from "../../../../util/FormFunctions";
import {Trans} from "react-i18next";

export const VALIDATE_NAME_SHORT = (name) => {
    return VALIDATE_STRING_SHORT(name, 3)
}

export const VALIDATE_NAME_LONG = (name) => {
    return VALIDATE_STRING_LONG(name, 20)
}

export const VALIDATE_LOAN_ASSET = (value) => {
    return VALIDATE_NUMBER(value, 10000, 10000000 * 2)
}

export function validateLoanAssetFromPrincipal(value, principal) {
    return principal > value
        ? (<Trans i18nKey={'validate_loan_asset_principal'} values={{principal: principal}}/>)
        : undefined;
}

export const VALIDATE_LOAN_PRINCIPAL = (value) => {
    return VALIDATE_NUMBER(value, 10000, 10000000)
}

export const VALIDATE_LOAN_RELATED_EXPENSES_PERCENTAGE = (value) => {
    return VALIDATE_NUMBER(value, 0, 200)
}

export const VALIDATE_LOAN_YEARS = (value) => {
    return VALIDATE_NUMBER(value, 1, 50)
}

export const VALIDATE_LOAN_INTEREST_RATE = (value) => {
    return VALIDATE_NUMBER(value, 0, 100)
}