import React, {useEffect, useState} from 'react';
import './BondingFormFrenchMortgageCalculator.css';
import './BondingFormFrenchMortgageCalculator-media.css';
import {Box, Button, MenuItem, Typography} from '@mui/material';
import Grid from '@mui/material/Grid2';
import {Trans, useTranslation} from 'react-i18next';
import {
    BONDING_BONUS,
    BONDING_COST,
    BONDING_FREQUENCY,
    BONDING_NAME,
    BONDING_YEARS,
    FREQUENCY,
    LOAN_YEARS,
    NAME
} from '../../../../constant/Mortgage';
import {isEmpty} from '../../../../util/FormFunctions';
import CommonTextField from '../../../CommonTextField/CommonTextField';
import CommonDoubleSlider from '../../../CommonDoubleSlider/CommonDoubleSlider';
import CommonInputAdornment from '../../../CommonInputAdornment/CommonInputAdornment';
import CommonSelect from '../../../CommonSelect/CommonSelect';
import {GoogleOAuthProvider} from '@react-oauth/google';
import config from '../../../../config';
import GoogleLogin from '../../../GoogleLogin/GoogleLogin';
import FormValidationManager from "../../../../util/FormValidationManager";
import {
    VALIDATE_NAME,
    VALIDATE_NAME_LONG,
    VALIDATE_NAME_SHORT
} from "./BondingFormFrenchMortgageCalculatorFormValidations";

export default function BondingFormFrenchMortgageCalculator(
    {formLoanValues, formValues, setFormValues, user, userLogged}
) {

    const {t} = useTranslation();

    const [bondingName, setBondingName] = useState(`Bonding name ${formValues.length}`);
    const [bondingCost, setBondingCost] = useState(0.0);
    const [bondingBonus, setBondingBonus] = useState(0.0);
    const [bondingYears, setBondingYears] = useState([1, parseInt(formLoanValues[LOAN_YEARS])]);
    const [bondingFrequency, setBondingFrequency] = useState(FREQUENCY[FREQUENCY.length - 1]);

    const [areBondingErrors, setAreBondingErrors] = useState(false);

    const [errors, setErrors] = useState(new Map())
    const formValidationManager = new FormValidationManager(errors, setErrors)

    const VALIDATE_NAME_REPEATED = (name) => {
        return formValues.some(bonding => bonding.bonding_name === name)
            ? (<Trans i18nKey={"bonding_name_error_already_exist"}/>)
            : undefined;
    }

    const resetBondingForm = (currentBondingList = 0, bondingList) => {
        while (bondingList.some(bonding => bonding.bonding_name === `Bonding name ${currentBondingList}`)) {
            currentBondingList += 1;
        }
        setBondingName(`Bonding name ${currentBondingList}`)
        setBondingCost(0.0)
        setBondingBonus(0.0)
        setBondingYears([1, parseInt(formLoanValues[LOAN_YEARS])])
        setBondingFrequency(FREQUENCY[FREQUENCY.length - 1])
    }

    const onSubmit = (_) => {
        const bonding = {
            [BONDING_NAME]: bondingName,
            [BONDING_COST]: bondingCost,
            [BONDING_BONUS]: bondingBonus,
            [BONDING_YEARS]: bondingYears,
            [BONDING_FREQUENCY]: bondingFrequency,
        }
        const bondingList = [...formValues, bonding]
        setFormValues(bondingList)
        resetBondingForm(bondingList.length, bondingList)
    }

    useEffect(() => {
        setAreBondingErrors(
            !errors || isEmpty(bondingCost) || isEmpty(bondingBonus)
        )
    }, [errors, bondingCost, bondingBonus]);

    const removeBonding = (index) => {
        const newFormValues = [...formValues];
        newFormValues.splice(index, 1);
        setFormValues(newFormValues);
    }

    return (
        <Grid id={'BondingFormFrenchMortgageCalculator'} container>
            <Grid item size={12}>
                <Typography className={'informative-form-paragraph'}>
                    <Trans i18nKey='bonding_form_advice' components={{1: <br/>}}/>
                </Typography>
            </Grid>
            {userLogged ? (
                <Grid className={'frame'} container direction={{sm: 'column', md: 'row'}}>
                    <Grid item className={'form-column'} size={{sm: 12, md: 6}}>
                        <CommonTextField
                            value={bondingName}
                            onChange={(event) => {
                                formValidationManager.validateErrors(
                                    NAME, event.target.value,
                                    [VALIDATE_NAME, VALIDATE_NAME_SHORT, VALIDATE_NAME_LONG, VALIDATE_NAME_REPEATED]
                                );
                                setBondingName(event.target.value)
                            }}
                            error={errors[NAME]}
                            helpText={
                                (errors[NAME])
                                    ? formValidationManager.getErrors(NAME)
                                    : <Trans i18nKey={'bonding_name_help_text'}/>
                            }
                            type={'normal'}
                            labelKey={BONDING_NAME}
                        />
                        <CommonTextField
                            value={bondingCost}
                            onChange={(event) => setBondingCost(event.target.value)}
                            type={'number'}
                            step={'0.1'}
                            labelKey={BONDING_COST}
                            error={isEmpty(bondingCost)}
                            helpText={<Trans i18nKey={'bonding_cost_help_text'}/>}
                        />
                        <CommonDoubleSlider
                            title={'Interest range'}
                            value={bondingYears}
                            onChange={(event) => setBondingYears(event.target.value)}
                            min={1}
                            max={parseInt(formLoanValues[LOAN_YEARS])}
                            helpText={<Trans i18nKey={'bonding_years_range_help_text'}/>}
                        />
                        <CommonTextField
                            value={bondingBonus}
                            onChange={(event) => setBondingBonus(event.target.value)}
                            type={'number'}
                            step={'0.1'}
                            labelKey={BONDING_BONUS}
                            error={isEmpty(bondingBonus)}
                            helpText={bondingBonus
                                ? <Trans i18nKey={'bonding_bonus_error_mandatory'}/>
                                : <Trans i18nKey={'bonding_bonus_help_text'}/>
                            }
                            InputProps={{
                                style: {
                                    color: 'white',
                                    borderRadius: '1em'
                                },
                                endAdornment: <CommonInputAdornment value={'%'}/>
                            }}
                        />
                        <CommonSelect
                            value={bondingFrequency}
                            id={BONDING_FREQUENCY}
                            onChange={(event) => setBondingFrequency(event.target.value)}
                            label={<Trans i18nKey={'bonding_frequency'}/>}
                            menuItems={
                                () => FREQUENCY.map((freq, index) => (
                                    <MenuItem key={`list-bonding-frequency-${index}`} value={freq}>
                                        <Trans i18nKey={`frequency_${freq}`}/>
                                    </MenuItem>
                                ))
                            }
                            helpText={<Trans i18nKey={'bonding_frequency_type_help_text'}/>}
                        />
                        <Button variant='contained' type='button' disabled={areBondingErrors || formValues.length >= 3}
                                onClick={onSubmit}>
                            <Trans i18nKey='bonding_add'/>
                        </Button>
                        <div style={{paddingTop: '1em'}}>
                        {formValues.length >= 3 &&
                            <Typography variant='p' sx={{color: 'white'}}>
                                <Trans i18nKey='bonding_form_limit_reached' values={{'account_type': user?.account?.type.toUpperCase() || ''}}/>
                            </Typography>
                        }
                        </div>
                    </Grid>
                    <Grid item id={'bonding-list'} className={'form-column'} size={{sm: 12, md: 6}}>
                        {formValues.length > 0 &&
                            <div>
                                <Typography variant={'h4'} paragraph={true}>
                                    <Trans i18nKey='bonding_list'/>
                                </Typography>
                                {
                                    formValues.map((bonding, index) => (
                                        <Box key={[`bonding-list-${index}`]}
                                             sx={{
                                                 border: '1px solid grey', borderRadius: '1em',
                                                 padding: '1em', marginBottom: '1em'
                                             }}
                                        >
                                            <Typography variant={'h4'} paragraph={true}>
                                                {bonding[BONDING_NAME]}
                                            </Typography>
                                            <Typography variant={'h5'} paragraph={true}>
                                                <Trans i18nKey={'bonding_from_up_to'} values={{
                                                    'bonding_starts': bonding[BONDING_YEARS][0],
                                                    'bonding_ends': bonding[BONDING_YEARS][1]
                                                }}/>
                                            </Typography>
                                            <Typography variant={'body1'} paragraph={true}>
                                                <Trans i18nKey={'bonding_reduces'} values={{
                                                    'bonding_cost': bonding[BONDING_COST],
                                                    'bonding_frequency': t(`frequency_${bonding[BONDING_FREQUENCY]}`).toLowerCase(),
                                                    'bonding_bonus': bonding[BONDING_BONUS]
                                                }}/>
                                            </Typography>
                                            <p className={'delete'} onClick={() => removeBonding(index)}>
                                                <Trans i18nKey={'delete'}/>
                                            </p>
                                        </Box>
                                    ))
                                }
                            </div>
                        }
                    </Grid>
                </Grid>
            ) : (
                <Grid item style={{textAlign: 'center'}} size={12}>
                    <GoogleOAuthProvider clientId={config.googleId}>
                        <GoogleLogin/>
                    </GoogleOAuthProvider>
                </Grid>
            )}
        </Grid>
    )
}
