import React from 'react';
import {FormControl, FormHelperText, InputLabel, Select} from "@mui/material";
import {MUI_STYLES} from "../../constant/Component";

export default function CommonSelect(
    {
        formRegister, id, value, label, onChange, helpText, menuItems, disabled = false,
        ...otherProps
    }
) {

    return (
        <FormControl fullWidth margin="normal">
            <InputLabel htmlFor={id} style={{color: 'white'}}>
                {label}
            </InputLabel>
            <Select
                {...formRegister}
                value={value}
                disabled={disabled}
                aria-describedby={`${id}_helper`}
                onChange={(event) => onChange(event)}
                sx={MUI_STYLES}
                {...otherProps}
            >
                {menuItems()}
            </Select>
            {helpText && <FormHelperText id={`${id}_helper`} style={{color: 'grey'}}>{helpText}</FormHelperText>}
        </FormControl>
    );
}