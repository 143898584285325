import MarkdownContent from '../component/Markdown/MarkdownContent';
import {Trans} from 'react-i18next';
import DoneIcon from '@mui/icons-material/Done';
import HomeIcon from '@mui/icons-material/Home';
import React from 'react';
import MortgageBlock from '../component/BlockPanel/custom/MortgageBlock';

export const HOME_BLOCKS = [
    {
        component: <MarkdownContent
            title={<Trans i18nKey={'block_manage_mortgages_effortless'}/>}
            icon={<HomeIcon sx={{fontSize: '1.2em', top: '0.2em', position: 'relative'}}/>}
            defaultContent={<MortgageBlock/>}
        />,
        size: 2,
        image: 'mortgage_background.jpg',
        url: '/tool/french-mortgage-calculator'
    },
    {
        component: <MarkdownContent
            title={<Trans i18nKey={'tool_mortgage_comparator'}/>}
            icon={<HomeIcon sx={{fontSize: '1.2em', top: '0.2em', position: 'relative'}}/>}
            page={'home_comparator'}
        />,
        size: 1,
        image: 'mortgage_comparator.jpg',
        url: '/tool/french-mortgage-comparator'
    },
    {
        component: <MarkdownContent
            title={<Trans i18nKey={'block_financial_glossary'}/>}
            icon={<DoneIcon sx={{fontSize: '1.2em', top: '0.2em', position: 'relative'}}/>}
            page={'home_glossary'}
        />,
        size: 3,
        image: 'financlal_glossary.jpg',
        url: '/financial-glossary'
    }
]