export const MUI_STYLES = {
    color: "white",
    '.MuiOutlinedInput-notchedOutline': {
        borderRadius: '1em',
        borderColor: 'rgba(228, 219, 233, 0.25)',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderRadius: '1em',
        borderColor: 'rgba(228, 219, 233, 0.25)',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
        borderRadius: '1em',
        borderColor: 'rgba(228, 219, 233, 0.25)',
    },
    '.MuiSvgIcon-root ': {
        fill: "white !important",
    }
}