export const sliderModifier = {
    '& .MuiSlider-thumb': {
        color: "grey"
    },
    '& .MuiSlider-track': {
        color: "grey"
    },
    '& .MuiSlider-rail': {
        color: "grey"
    },
    '& .MuiSlider-active': {
        color: "grey"
    }
}