import {Helmet} from "react-helmet";
import React from "react";
import {LNG_FINANCIAL_GLOSSARY, LNG_SEO} from "../i18n";

export const metaSEO = (i18n, page, index = true) => <>
    {i18n &&
        <Helmet>
            <title>{(page !== 'home') ? i18n(`${page}_title`) + ' | ' : ''}FinancialBreaker.com</title>
            <meta name="description" content={i18n(`${page}_description`)}/>
            <meta name="keywords" content={i18n(`${page}_keywords`)}/>
            <meta name="robots" content={index ? "index, follow" : "noindex, follow"}/>
        </Helmet>
    }
</>

export const financialTermMetaSEO = (i18n, term, ns = LNG_FINANCIAL_GLOSSARY, index = true) => <>
    {i18n &&
        <Helmet>
            <title>{i18n(`${ns}:${term.replace(/-/g, '_')}`) + ' | '}FinancialBreaker.com</title>
            <meta name="description" content={i18n(`${LNG_SEO}:financial_glossary_description`)}/>
            <meta name="keywords" content={i18n(`${LNG_SEO}:financial_glossary_keywords`)}/>
            <meta name="robots" content={index ? "index, follow" : "noindex, follow"}/>
        </Helmet>
    }
</>