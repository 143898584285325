import React from "react";
import './Mortgage.css'
import Grid from "@mui/material/Grid2";
import MarkdownPage from "../../component/Markdown/MarkdownPage";

export default function Mortgage() {

    return (
        <Grid id={"Mortgage"} container>
            <Grid item size={12}>
                <MarkdownPage page={'mortgage'}/>
            </Grid>
        </Grid>
    );
}