import React, {useState} from 'react';
import './LoanFormFrenchMortgageCalculator.css';
import {handleMaxDecimalsOnInputChange} from "../../../../util/FormFunctions";
import {MenuItem, Typography} from "@mui/material";
import Grid from "@mui/material/Grid2";
import {Trans} from "react-i18next";
import {
    DEFAULT,
    FREQUENCY,
    LOAN_ASSET,
    LOAN_FIXED_INTEREST_RATE,
    LOAN_FIXED_YEARS,
    LOAN_PRINCIPAL,
    LOAN_RELATED_EXPENSES_PERCENTAGE,
    LOAN_VARIABLE_INTEREST_RATE,
    LOAN_VARIABLE_INTEREST_RATE_RANGE,
    LOAN_VARIABLE_INTEREST_RATE_UPDATE_FREQUENCY,
    LOAN_VARIABLE_YEARS,
    LOAN_YEARS,
    NAME
} from "../../../../constant/Mortgage";
import CommonTextField from "../../../CommonTextField/CommonTextField";
import CommonSlider from "../../../CommonSlider/CommonSlider";
import CommonDoubleSlider from "../../../CommonDoubleSlider/CommonDoubleSlider";
import CommonInputAdornment from "../../../CommonInputAdornment/CommonInputAdornment";
import CommonSelect from "../../../CommonSelect/CommonSelect";
import CommonCheckbox from "../../../CommonCheckbox/CommonCheckbox";
import FormValidationManager from "../../../../util/FormValidationManager";
import {
    VALIDATE_LOAN_ASSET,
    VALIDATE_LOAN_INTEREST_RATE,
    VALIDATE_LOAN_PRINCIPAL,
    VALIDATE_LOAN_RELATED_EXPENSES_PERCENTAGE,
    VALIDATE_LOAN_YEARS,
    VALIDATE_NAME_LONG,
    VALIDATE_NAME_SHORT,
    validateLoanAssetFromPrincipal
} from "./LoanFormFrenchMortgageCalculatorFormValidators";

export default function LoanFormFrenchMortgageCalculator(
    {
        formRegister, formValues, setFormValues, formGeneralInfo,
        setFormGeneralInfo, userLogged, selectedMortgage
    }
) {

    const setFormValue = (key, value) => {
        setFormValues({...formValues, [key]: value});
    }

    const setFormGeneralInfoValues = (key, value) => {
        setFormGeneralInfo({...formGeneralInfo, [key]: value});
    }

    const [errors, setErrors] = useState(new Map())
    const formValidationManager = new FormValidationManager(errors, setErrors)

    return (
        <Grid id={"LoanFormFrenchMortgageCalculator"} container>
            <Grid item xs={12}>
                <Typography className={"informative-form-paragraph"}>
                    <Trans i18nKey={'loan_form_advice'} components={{1: <br/>}}/>
                </Typography>
            </Grid>
            <Grid container className={"frame"} direction={{xs: 'column', md: 'row'}}>
                <Grid item className={"form-column"} size={{xs: 12, md: 6}}>
                    {userLogged && <>
                        <CommonCheckbox
                            formRegister={formRegister(DEFAULT)}
                            componentName={DEFAULT}
                            checked={formGeneralInfo[DEFAULT]}
                            onChange={(event) => setFormGeneralInfoValues(DEFAULT, event.target.checked)}
                            labelKey={<Trans i18nKey={'loan_default'}/>}
                            helpText={
                                <ul className={'help-text'}>
                                    <li>{<Trans i18nKey={'loan_default_help_text_01'}/>}</li>
                                    <li>{<Trans i18nKey={'loan_default_help_text_02'}/>}</li>
                                </ul>
                            }
                        />
                        <CommonTextField
                            formRegister={formRegister(NAME)}
                            value={formGeneralInfo[NAME]}
                            disabled={formGeneralInfo[NAME] === selectedMortgage}
                            onChange={(event) => {
                                formValidationManager.validateErrors(NAME, event.target.value, [VALIDATE_NAME_SHORT, VALIDATE_NAME_LONG]);
                                setFormGeneralInfoValues(NAME, event.target.value)
                            }}
                            error={errors[NAME]}
                            helpText={
                                (errors[NAME])
                                    ? formValidationManager.getErrors(NAME)
                                    : <Trans i18nKey={'loan_name_help_text'}/>
                            }
                            required={true}
                            type={"text"}
                            label={<Trans i18nKey={'loan_name'}/>}
                        />
                    </>
                    }
                    <CommonTextField
                        formRegister={formRegister(LOAN_ASSET)}
                        value={formValues[LOAN_ASSET]}
                        onChange={(event) => {
                            const VALIDATE_LOAN_ASSET_FROM_PRINCIPAL = (value) => {
                                return validateLoanAssetFromPrincipal(value, formValues[LOAN_PRINCIPAL]);
                            }
                            formValidationManager.validateErrors(LOAN_ASSET, event.target.value, [VALIDATE_LOAN_ASSET, VALIDATE_LOAN_ASSET_FROM_PRINCIPAL]);
                            setFormValue(LOAN_ASSET, event.target.value)
                        }}
                        error={errors[LOAN_ASSET]}
                        helpText={
                            (errors[LOAN_ASSET])
                                ? formValidationManager.getErrors(LOAN_ASSET)
                                : <Trans i18nKey={'loan_asset_help_text'}/>
                        }
                        required={true}
                        type={"number"}
                        labelKey={LOAN_ASSET}
                        step={"0.1"}
                    />
                    <CommonTextField
                        formRegister={formRegister(LOAN_PRINCIPAL)}
                        value={formValues[LOAN_PRINCIPAL]}
                        onChange={(event) => {
                            formValidationManager.validateErrors(LOAN_PRINCIPAL, event.target.value, [VALIDATE_LOAN_PRINCIPAL]);
                            setFormValue(LOAN_PRINCIPAL, event.target.value)
                        }}
                        error={errors[LOAN_PRINCIPAL]}
                        helpText={
                            (errors[LOAN_PRINCIPAL])
                                ? formValidationManager.getErrors(LOAN_PRINCIPAL)
                                : <Trans i18nKey={'loan_principal_help_text'}/>
                        }
                        required={true}
                        type={"number"}
                        labelKey={LOAN_PRINCIPAL}
                        step={"0.1"}
                    />
                    <CommonTextField
                        formRegister={formRegister(LOAN_RELATED_EXPENSES_PERCENTAGE)}
                        value={formValues[LOAN_RELATED_EXPENSES_PERCENTAGE]}
                        onChange={(event) => {
                            formValidationManager.validateErrors(LOAN_RELATED_EXPENSES_PERCENTAGE, event.target.value, [VALIDATE_LOAN_RELATED_EXPENSES_PERCENTAGE]);
                            setFormValue(LOAN_RELATED_EXPENSES_PERCENTAGE, event.target.value);
                        }}
                        error={errors[LOAN_RELATED_EXPENSES_PERCENTAGE]}
                        helpText={
                            (errors[LOAN_RELATED_EXPENSES_PERCENTAGE])
                                ? formValidationManager.getErrors(LOAN_RELATED_EXPENSES_PERCENTAGE)
                                : <Trans i18nKey={'loan_related_expenses_help_text'}/>
                        }
                        required={true}
                        type="number"
                        labelKey={LOAN_RELATED_EXPENSES_PERCENTAGE}
                        step={"0.1"}
                        InputProps={{
                            min: "0",
                            max: "50",
                            step: "0.1",
                            style: {
                                color: 'white',
                                borderRadius: "1em"
                            },
                            endAdornment: <CommonInputAdornment value={"%"}/>,
                            inputProps: {
                                // this will restrict unwanted user input
                                pattern: "^([0-9]*[.]?[0-9]*)$"
                            }
                        }}
                    />
                    <CommonTextField
                        formRegister={formRegister(LOAN_YEARS)}
                        value={formValues[LOAN_YEARS]}
                        onChange={(event) => {
                            formValidationManager.validateErrors(LOAN_YEARS, event.target.value, [VALIDATE_LOAN_YEARS]);
                            setFormValue(LOAN_YEARS, event.target.value);
                        }}
                        error={errors[LOAN_YEARS]}
                        helpText={
                            (errors[LOAN_YEARS])
                                ? formValidationManager.getErrors(LOAN_YEARS)
                                : <Trans i18nKey={'loan_years_help_text'}/>
                        }
                        required={true}
                        type="number"
                        labelKey={LOAN_YEARS}
                    />
                    <CommonSlider
                        title={"Fixed Years *"}
                        value={formValues[LOAN_FIXED_YEARS]}
                        max={formValues[LOAN_YEARS]}
                        onChange={(event) => setFormValue(LOAN_FIXED_YEARS, event.target.value)}
                        helpText={<Trans i18nKey={'loan_fixed_years_help_text'}/>}
                    />
                    <CommonTextField
                        formRegister={formRegister(LOAN_FIXED_INTEREST_RATE)}
                        value={formValues[LOAN_FIXED_INTEREST_RATE]}
                        onChange={(event) => {
                            handleMaxDecimalsOnInputChange(
                                event,
                                () => {
                                    formValidationManager.validateErrors(LOAN_FIXED_INTEREST_RATE, event.target.value, [VALIDATE_LOAN_INTEREST_RATE]);
                                    setFormValue(LOAN_FIXED_INTEREST_RATE, event.target.value)
                                },
                                ''
                            )
                        }}
                        error={errors[LOAN_FIXED_INTEREST_RATE]}
                        helpText={
                            (errors[LOAN_FIXED_INTEREST_RATE])
                                ? formValidationManager.getErrors(LOAN_FIXED_INTEREST_RATE)
                                : <Trans i18nKey={'loan_fixed_interest_rate_help_text'}/>
                        }
                        required={true}
                        labelKey={LOAN_FIXED_INTEREST_RATE}
                        step={"0.1"}
                        InputProps={{
                            style: {
                                color: 'white',
                                borderRadius: "1em"
                            },
                            endAdornment: <CommonInputAdornment value={"%"}/>
                        }}
                    />
                </Grid>
                <Grid item className={"form-column"}  size={{xs: 12, md: 6}}>
                    <CommonSlider
                        title={<Trans i18nKey={'loan_variable_years'}/>}
                        value={parseInt(formValues[LOAN_YEARS]) - parseInt(formValues[LOAN_FIXED_YEARS])}
                        max={formValues[LOAN_YEARS]}
                        onChange={(event) => setFormValue(LOAN_VARIABLE_YEARS, event.target.value)}
                        helpText={<Trans i18nKey={'loan_variable_years_help_text'}/>}
                    />
                    <CommonTextField
                        formRegister={formRegister(LOAN_VARIABLE_INTEREST_RATE)}
                        value={formValues[LOAN_VARIABLE_INTEREST_RATE]}
                        onChange={(event) => {
                            handleMaxDecimalsOnInputChange(
                                event,
                                () => {
                                    formValidationManager.validateErrors(LOAN_VARIABLE_INTEREST_RATE, event.target.value, [VALIDATE_LOAN_INTEREST_RATE]);
                                    setFormValue(LOAN_VARIABLE_INTEREST_RATE, event.target.value)
                                },
                                ''
                            )
                        }}
                        error={errors[LOAN_VARIABLE_INTEREST_RATE]}
                        helpText={
                            (errors[LOAN_VARIABLE_INTEREST_RATE])
                                ? formValidationManager.getErrors(LOAN_VARIABLE_INTEREST_RATE)
                                : <Trans i18nKey={'loan_variable_interest_rate_help_text'}/>
                        }
                        type="number"
                        labelKey={LOAN_VARIABLE_INTEREST_RATE}
                        disabled={parseInt(formValues[LOAN_VARIABLE_YEARS]) === 0}
                        InputProps={{
                            style: {
                                color: 'white',
                                step: 0.01,
                                borderRadius: "1em"
                            },
                            endAdornment: <CommonInputAdornment value={"%"}/>
                        }}
                    />
                    <CommonDoubleSlider
                        title={<Trans i18nKey={'loan_variable_interest_rate_range'}/>}
                        value={formValues[LOAN_VARIABLE_INTEREST_RATE_RANGE]}
                        onChange={(event) => setFormValue(
                            LOAN_VARIABLE_INTEREST_RATE_RANGE,
                            [parseFloat(event.target.value[0]), parseFloat(event.target.value[1])]
                        )}
                        helpText={<Trans i18nKey={'loan_variable_interest_rate_range_help_text'}/>}
                        max={10}
                        step={0.1}
                    />
                    <CommonSelect
                        formRegister={formRegister(LOAN_VARIABLE_INTEREST_RATE_UPDATE_FREQUENCY)}
                        id={LOAN_VARIABLE_INTEREST_RATE_UPDATE_FREQUENCY}
                        value={formValues[LOAN_VARIABLE_INTEREST_RATE_UPDATE_FREQUENCY]}
                        label={<Trans i18nKey={'loan_variable_interest_rate_frequency'}/>}
                        disabled={parseInt(formValues[LOAN_VARIABLE_YEARS]) === 0}
                        onChange={(event) => setFormValue(LOAN_VARIABLE_INTEREST_RATE_UPDATE_FREQUENCY, event.target.value)}
                        menuItems={
                            () => FREQUENCY.map((freq, index) => (
                                <MenuItem key={`list-variable-interest-rate-frequency-${index}`} value={freq}>
                                    <Trans i18nKey={`frequency_${freq}`}/>
                                </MenuItem>
                            ))
                        }
                        helpText={<Trans i18nKey={'loan_variable_interest_rate_frequency_help_text'}/>}
                    />
                </Grid>
            </Grid>
        </Grid>
    )
}
