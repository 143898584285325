import React from 'react'
import './BlockPanel.css'
import Grid from "@mui/material/Grid2"
import {useNavigate} from 'react-router-dom'
import {useMediaQuery, useTheme} from "@mui/material";

export default function BlockPanel({blocks}) {

    const navigate = useNavigate()

    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('mi'))

    const handleClick = (link) => {
        if (link) {
            navigate(link)
        }
    }

    return (
        <Grid container id={"BlockPanel"} columns={3} spacing={0}>
            {blocks.map((block, index) =>
                <Grid item size={isMobile ? 3 : block.size} key={`block-panel-list-${index}`} className={"panel"}
                      onMouseEnter={e => {
                          e.currentTarget.querySelector('.frame').style.transform = 'scale(1.03)'
                          e.currentTarget.querySelector('.frame').style.boxShadow = '0 4px 8px rgba(255,255,255,0.2)'
                          e.currentTarget.querySelector('.overlay').style.opacity = '80%'
                      }}
                      onMouseLeave={e => {
                          e.currentTarget.querySelector('.frame').style.transform = 'scale(1)'
                          e.currentTarget.querySelector('.frame').style.boxShadow = 'none'
                          e.currentTarget.querySelector('.overlay').style.opacity = '70%'
                      }}
                      onClick={() => handleClick(block.url)}
                >
                    <div className={'frame'} style={{
                        backgroundImage: `url('/images/${block.image}')`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center'
                    }}>
                        <div className="overlay">
                            {block.component}
                        </div>
                    </div>
                </Grid>
            )}
        </Grid>
    )
}