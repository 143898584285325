import React from 'react';
import './FrenchAmortizationCostPieChart.css';
import {Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip} from 'recharts';
import {Box, Typography} from "@mui/material";
import {AMORTIZATION_COLOR, BONDING_COLOR, INTEREST_RATE_COLOR} from "../../../../constant/Color";
import {ACC_AMORTIZATION, ACC_BONDING, ACC_INTEREST_RATE} from "../../../../constant/AmortizationMatrix";
import {Trans} from "react-i18next";

export function FrenchAmortizationCostPieChart({amortizationMatrix}) {

    const COLORS = [AMORTIZATION_COLOR, INTEREST_RATE_COLOR, BONDING_COLOR];

    const amortizationMatrixLastRow = amortizationMatrix[amortizationMatrix.length - 1];
    const totalValue = amortizationMatrixLastRow[ACC_AMORTIZATION] + amortizationMatrixLastRow[ACC_INTEREST_RATE] + amortizationMatrixLastRow[ACC_BONDING];
    const data = [
        {name: 'Amortization', value: (amortizationMatrixLastRow[ACC_AMORTIZATION] / totalValue) * 100},
        {name: 'Interest Rate', value: (amortizationMatrixLastRow[ACC_INTEREST_RATE] / totalValue) * 100},
        {name: 'Bonding', value: (amortizationMatrixLastRow[ACC_BONDING] / totalValue) * 100}
    ];

    // const renderLabel = ({name, value}) => `${name}: ${value.toFixed(2)}%`;

    return (
        <Box id={"FrenchAmortizationCostPieChart"}>
            <ResponsiveContainer width="100%" height={300}>
                <PieChart>
                    <Pie
                        data={data}
                        cx="50%"
                        cy="50%"
                        labelLine={false}
                        outerRadius={100}
                        fill="#8884d8"
                        dataKey="value"
                        //label={renderLabel}
                    >
                        {data.map((entry, index) => (
                            <Cell key={index} fill={COLORS[index % COLORS.length]}/>
                        ))}
                    </Pie>
                    <Tooltip/>
                    <Legend/>
                </PieChart>
            </ResponsiveContainer>
            <Typography>
                <Trans i18nKey={'graph_accumulated_cost_pie_help_text'} components={{br: <br/>}}/>
            </Typography>
        </Box>
    );
}