import React, {useEffect, useState} from 'react';
import './FrenchMortgageInterestRateMatrix.css'
import {Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography} from "@mui/material";
import {AMORTIZATION_COLOR_HOVER, MONTHLY_PAYMENT_COLOR} from "../../../constant/Color";
import {Trans} from "react-i18next";

export default function FrenchMortgageInterestRateMatrix({amortizationMatrix}) {

    const [interestRates, setInterestRates] = useState({});

    function sumInterestRates() {
        return amortizationMatrix.reduce((acc, {period, period_interest_rate}) => {
            const year = Math.floor(period / 12);
            if (!acc[year]) {
                acc[year] = 0;
            }
            acc[year] += parseFloat(period_interest_rate);
            return acc;
        }, {});
    }

    useEffect(() => {
        if (amortizationMatrix) {
            setInterestRates(sumInterestRates());
        }
    }, [amortizationMatrix]);

    return (
        <Grid id={"FrenchMortgageInterestRateMatrix"} container>
            <Grid item xs={"12"}>
                <Typography variant={"h5"}>
                    <Trans i18nKey={"interest_rate"}/> <Trans i18nKey={"per_year"}/>
                </Typography>
            </Grid>
            <Grid item xs={"12"} style={{marginTop: '0.7em'}}>
                <TableContainer id={"matrix"} component={Paper} style={{
                    paddingRight: '1em',
                    paddingBottom: '1em',
                    maxHeight: '20em',
                    overflowY: 'auto',
                    scrollbarWidth: 'thin',
                    scrollbarColor: `#ccc ${AMORTIZATION_COLOR_HOVER}`
                }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell><Trans i18nKey={"year"}/></TableCell>
                                <TableCell><Trans i18nKey={"interest_rate"}/></TableCell>
                                <TableCell><Trans i18nKey={"year"}/></TableCell>
                                <TableCell><Trans i18nKey={"interest_rate"}/></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Object.entries(interestRates).reduce((acc, [year, totalInterestRate], index, array) => {
                                if (index % 2 === 0) {
                                    acc.push([year, totalInterestRate, array[index + 1]?.[0], array[index + 1]?.[1]]);
                                }
                                return acc;
                            }, []).map(([year1, totalInterestRate1, year2, totalInterestRate2], index) => (
                                <TableRow key={`total-interest-rate-per-year-${index}`}>
                                    {year1 && <>
                                        <TableCell style={{
                                            color: MONTHLY_PAYMENT_COLOR,
                                            fontWeight: "bold"
                                        }}>{parseInt(year1) + 1}</TableCell>
                                        <TableCell style={{
                                            color: 'white',
                                            fontWeight: "bold"
                                        }}>{totalInterestRate1.toFixed(2)}</TableCell>
                                    </>}
                                    {year2 && <>
                                        <TableCell style={{
                                            color: MONTHLY_PAYMENT_COLOR,
                                            fontWeight: "bold"
                                        }}>{parseInt(year2) + 1}</TableCell>
                                        <TableCell style={{
                                            color: 'white',
                                            fontWeight: "bold"
                                        }}>{totalInterestRate2 ? totalInterestRate2.toFixed(2) : ''}</TableCell>
                                    </>}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>
    );
}