import React from 'react';
import './SuperLink.css';
import {useNavigate} from 'react-router-dom';
import {Typography} from '@mui/material';


export default function SuperLink({link, title, color, highlightedColor, opacity = 1}) {

    const navigate = useNavigate()

    const handleClick = (link) => {
        if (link) {
            navigate(link)
        }
    }

    return (
        <div className={'SuperLink'} onClick={() => handleClick(link)}
             style={{
                 margin: '0 0.5em',
                 padding: '2em 5em',
                 backgroundColor: color,
                 transition: 'background-color 0.3s',
                 opacity: opacity,
                 textDecoration: 'none'
             }}
             onMouseEnter={e => e.target.style.backgroundColor = highlightedColor}
             onMouseLeave={e => e.target.style.backgroundColor = color}>
            <Typography variant='h4'>{title}</Typography>
        </div>
    )
}