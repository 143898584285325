import React, {useEffect, useState} from 'react';
import './Markdown.css'
import './MarkdownContent.css'
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import {useTranslation} from 'react-i18next'
import rehypeRaw from "rehype-raw";
import Grid from "@mui/material/Grid2";
import axios from "axios";
import config from "../../config";

export default function MarkdownContent({title, page = undefined, defaultContent = undefined, icon}) {

    const {i18n} = useTranslation();
    const language = i18n?.language?.split('-')[0] || 'en';

    const [content, setContent] = useState('');

    useEffect(() => {
        if (page) {
            axios.get(`${config.apiUrl}/api/v1/content/financial-glossary/${language}/${page}`, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(response => {
                if (response.status === 200 && Boolean(response.data.exists) === true) {
                    setContent(response.data.content);
                }
            }).catch(err => console.log(err));
        }
    }, [page]);

    return (
        <Grid container className={"MarkdownContent"}>
            <Grid item className={"markdown-title"} size='auto'>{icon} {title}</Grid>
            <hr style={{width: '100%', margin: '1em 0'}}/>
            <Grid item className={"content"} size='auto' style={{padding: '0 1em'}}>
                {content && <ReactMarkdown className={"Markdown"}
                                           rehypePlugins={[rehypeRaw]}
                                           remarkPlugins={[remarkGfm]}
                >
                    {content}
                </ReactMarkdown>
                }
                {defaultContent && defaultContent}
            </Grid>
        </Grid>
    );
}
