import React from 'react';
import './CommonChip.css';
import {Link} from "react-router-dom";

export function CommonChip(
    {text, value, color, colorHover, link}
) {

    return (
        <div className={"CommonChip"}
             style={{backgroundColor: color, transition: "background-color 1s ease-in, background-color 0.2s ease-out"}}
             onMouseEnter={e => e.currentTarget.style.backgroundColor = colorHover ? colorHover : color}
             onMouseLeave={e => e.currentTarget.style.backgroundColor = color}>
            <Link to={link} target="_blank" onClick={event => (!link) ? event.preventDefault() : undefined}>
                <p style={{fontWeight: "bold"}}>{value}</p>
                <p style={{fontSize: "0.7em"}}>{text}</p>
            </Link>
        </div>
    );
}