export default class FormValidationManager {

    errors = undefined
    setErrors = undefined

    constructor(errors, setErrors) {
        this.errors = errors;
        this.setErrors = setErrors;
    }

    validateErrors(field, value, validators) {
        const fieldErrors = validators.map(validator => validator(value || "")).filter(error => error !== undefined);
        if (fieldErrors.length === 0) {
            this.setErrors(prevErrors => {
                const newErrors = {...prevErrors};
                delete newErrors[field];
                return newErrors;
            });
        } else {
            this.setErrors(prevErrors => ({
                ...prevErrors,
                [field]: fieldErrors
            }));
        }
    }

    getErrors(field) {
        if (this.errors[field] === undefined) return (<></>)
        const fieldErrors = this.errors[field];
        return (
            <ul className={"help-text"}>
                {fieldErrors.map((error, index) => <li key={`list-error-${field}-${index}`}>{error}</li>)}
            </ul>
        )
    }

}
