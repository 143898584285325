export const NAME = 'name';
export const DEFAULT = 'default';
export const LOAN_FIXED_INTEREST_RATE = 'loan_fixed_interest_rate';
export const LOAN_ASSET = 'loan_asset';
export const LOAN_PRINCIPAL = 'loan_principal';
export const LOAN_RELATED_EXPENSES_PERCENTAGE = 'loan_related_expenses_percentage';
export const LOAN_YEARS = 'loan_years';
export const LOAN_FIXED_YEARS = 'loan_fixed_years';
export const LOAN_VARIABLE_YEARS = 'loan_variable_years';
export const LOAN_VARIABLE_INTEREST_RATE = 'loan_variable_interest_rate';
export const LOAN_VARIABLE_INTEREST_RATE_RANGE = 'loan_variable_interest_rate_range';
export const LOAN_VARIABLE_INTEREST_RATE_UPDATE_FREQUENCY = 'loan_variable_interest_rate_update_frequency';
export const AMORTIZATION_QUANTITY = 'amortization_quantity';
export const AMORTIZATION_RATE_FREQUENCY = 'amortization_rate_frequency';
export const AMORTIZATION_TYPE = 'amortization_type';
export const AMORTIZATION_MONTHLY_PAYMENT_THRESHOLD_FROM_QUOTA_TO_PERIOD = 'amortization_monthly_payment_threshold_from_quota_to_period';
export const BONDING_NAME = 'bonding_name';
export const BONDING_COST = 'bonding_cost';
export const BONDING_BONUS = 'bonding_bonus';
export const BONDING_YEARS = 'bonding_years';
export const BONDING_FREQUENCY = 'bonding_frequency';
export const BONDING_QUANTITY = 'bonding_quantity';

export const AMORTIZATION_TYPES = ['quota', 'period']
export const FREQUENCY = ['monthly', 'quarterly', 'semiannual', 'yearly']

export const DEFAULT_FORM_GENERAL_INFO_VALUES = {
    [NAME]: 'New Mortgage',
    [DEFAULT]: false,
};

export const DEFAULT_FORM_LOAN_VALUES = {
    [LOAN_ASSET]: 10000,
    [LOAN_PRINCIPAL]: 10000,
    [LOAN_RELATED_EXPENSES_PERCENTAGE]: 0,
    [LOAN_YEARS]: 10,
    [LOAN_FIXED_INTEREST_RATE]: 0,
    [LOAN_FIXED_YEARS]: 10,
    [LOAN_VARIABLE_INTEREST_RATE]: 0,
    [LOAN_VARIABLE_YEARS]: 0,
    [LOAN_VARIABLE_INTEREST_RATE_RANGE]: [0.0, 1.5],
    [LOAN_VARIABLE_INTEREST_RATE_UPDATE_FREQUENCY]: FREQUENCY[FREQUENCY.length - 1]
};

export const DEFAULT_FORM_AMORTIZATION_VALUES = {
    [AMORTIZATION_QUANTITY]: 0,
    [AMORTIZATION_RATE_FREQUENCY]: FREQUENCY[1],
    [AMORTIZATION_TYPE]: AMORTIZATION_TYPES[0],
    [AMORTIZATION_MONTHLY_PAYMENT_THRESHOLD_FROM_QUOTA_TO_PERIOD]: 0,
};

export const DEFAULT_FORM_BONDING_VALUES = [];