export const PERIOD_COLOR = 'wheat';
export const AMORTIZATION_COLOR = '#195782';
export const AMORTIZATION_COLOR_HOVER = '#134162';
export const MONTHLY_PAYMENT_COLOR = '#6a73db';
export const MONTHLY_PAYMENT_COLOR_HOVER = '#5057a5';
export const INTEREST_RATE_COLOR = '#ea5353';
export const INTEREST_RATE_COLOR_HOVER = '#a73b3b';
export const BONDING_COLOR = '#c28100';
export const BONDING_COLOR_HOVER = '#785000';

export const SECONDARY_ITEMS = "#05453d"
export const SECONDARY_ITEMS_LIGHT = "#0c5a50"