import React from "react"
import './Tools.css'
import {Typography} from "@mui/material"
import Grid from "@mui/material/Grid2"
import {Link} from "react-router-dom"
import {Trans, useTranslation} from "react-i18next"
import {LNG_SEO} from "../../i18n";
import {metaSEO} from "../../util/SEOTools";

export default function Tools() {

    const {t: i18n} = useTranslation(LNG_SEO)

    return (
        <Grid container id={"Tools"}>
            {metaSEO(i18n, 'tools')}
            <Grid item size={12}>
                <Typography variant='h1' className={'page'}>
                    <Trans i18nKey={'tools'}/>
                </Typography>
                <hr style={{width: '100%', margin: '2em 0'}}/>
                <ul>
                    <li>
                        <Typography variant="h2" className={'main'}>
                            <Trans i18nKey={'mortgage'}/>
                        </Typography>
                        <ul>
                            <li>
                                <Link to={`/tool/french-mortgage-calculator`}>
                                    <Typography variant="h3" className={'main'}>
                                        <Trans i18nKey={'tool_mortgage_calculator'}/>
                                    </Typography>
                                </Link>
                            </li>
                            <li>
                                <Link to={`/tool/french-mortgage-comparator`}>
                                    <Typography variant="h3" className={'main'}>
                                        <Trans i18nKey={'tool_mortgage_comparator'}/>
                                    </Typography>
                                </Link>
                            </li>
                        </ul>
                    </li>
                </ul>
            </Grid>
        </Grid>
    )
}