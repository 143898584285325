import React from 'react';
import './Home.css';
import {Typography} from '@mui/material';
import Grid from '@mui/material/Grid2';
import BlockPanel from '../../component/BlockPanel/BlockPanel';
import {BONDING_COLOR, MONTHLY_PAYMENT_COLOR, SECONDARY_ITEMS_LIGHT} from '../../constant/Color';
import {Trans, useTranslation} from 'react-i18next';
import {HOME_BLOCKS} from "../../global/HomeBlocks";
import {HOME_SUPER_LINKS} from "../../global/HomeSuperLinks";
import {metaSEO} from "../../util/SEOTools";
import {LNG_SEO} from "../../i18n";

const Home = () => {

    const {t: i18n} = useTranslation([LNG_SEO])

    return (
        <Grid container id={'Home'}>
            {metaSEO(i18n, 'home')}
            <Grid item size={12} style={{
                minHeight: '30em',
                padding: '1em',
                backgroundImage: "url('/images/home_panel.jpg')",
                backgroundPosition: 'center'
            }}>
                <Typography className={'slogan'} variant="h1">
                    <p><Trans i18nKey={'slogan_01'} lang={'es'}/></p>
                    <p><Trans i18nKey={'slogan_02'}/></p>
                    <p>
                        <Trans i18nKey={'slogan_03'}
                               components={{
                                   green: <span style={{fontWeight: 'bold', color: SECONDARY_ITEMS_LIGHT}}/>,
                                   wheat: <span style={{fontWeight: 'bold', color: BONDING_COLOR}}/>,
                                   blue: <span style={{fontWeight: 'bold', color: MONTHLY_PAYMENT_COLOR}}/>
                               }}/>
                    </p>
                </Typography>
                <Grid item size={12} style={{display: 'flex', marginTop: '2em', justifyContent: 'left'}}>
                    {HOME_SUPER_LINKS.map((link) => link)}
                </Grid>
            </Grid>
            <hr style={{width: '100%', margin: '3em 0 2em 0'}}/>
            <BlockPanel blocks={HOME_BLOCKS}/>
        </Grid>
    );
};

export default Home;
