import React from 'react'
import './FinancialGlossaryTerm.css'
import {useParams} from 'react-router-dom'
import Grid from '@mui/material/Grid2'
import MarkdownPage from '../../component/Markdown/MarkdownPage'
import Loan from '../Loan/Loan'
import Mortgage from '../Mortgage/Mortgage'
import {useTranslation} from "react-i18next";
import {financialTermMetaSEO} from "../../util/SEOTools";
import {LNG_FINANCIAL_GLOSSARY, LNG_SEO} from "../../i18n";

export default function FinancialGlossaryTerm() {

    const staticPages = {
        'loan': <Loan/>,
        'mortgage': <Mortgage/>
    }

    const page = (useParams()?.page || '').toLowerCase()
    const subpage = (useParams()?.subpage || '').toLowerCase()

    const isStaticPage = Object.keys(staticPages).includes(page)

    const shouldShowStaticPage = (!subpage || subpage === '') && isStaticPage

    const {t: i18n} = useTranslation(
        [LNG_SEO, LNG_FINANCIAL_GLOSSARY]
    )

    return (
        <Grid container id={`FinancialGlossaryTerm`}>
            {
                financialTermMetaSEO(i18n, subpage ? subpage : page)
            }
            <Grid item size={12}>
                {shouldShowStaticPage && <>{staticPages[page]}</>}
                {!shouldShowStaticPage && <MarkdownPage page={page} subpage={subpage}/>}
            </Grid>
        </Grid>
    )
}