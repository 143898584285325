import React from "react";
import './FrenchAmortizationCostChart.css';
import {Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';
import {AMORTIZATION_COLOR, BONDING_COLOR, INTEREST_RATE_COLOR} from "../../../../constant/Color";
import {Box, Typography} from "@mui/material";
import {ACC_AMORTIZATION, ACC_BONDING, ACC_INTEREST_RATE} from "../../../../constant/AmortizationMatrix";
import {Trans} from "react-i18next";

export function FrenchAmortizationCostChart({amortizationMatrix}) {

    /**
     * The length of a bar in a bar chart.
     *
     * @type {number}
     */
    const barChartLength = 20;

    /**
     * Summarizes a given segment by extracting specific financial metrics.
     *
     * @param {Array} segment - The array of segment data objects.
     * @param {number} end - The end period index.
     * @returns {Object} An object containing summarized segment information:
     *  - `period`: The middle period calculated as the floor value of the average of start and end.
     *  - `ACC_AMORTIZATION`: The amortization value from the last entry of the segment.
     *  - `ACC_INTEREST_RATE`: The interest rate from the last entry of the segment.
     *  - `ACC_BONDING`: The bonding value from the last entry of the segment.
     */
    const summarizeSegment = (segment, end) => {
        return {
            period: end - 1,
            [ACC_AMORTIZATION]: segment[segment.length - 1][ACC_AMORTIZATION],
            [ACC_INTEREST_RATE]: segment[segment.length - 1][ACC_INTEREST_RATE],
            [ACC_BONDING]: segment[segment.length - 1][ACC_BONDING],
        };
    }

    /**
     * Generates data for a bar chart using an amortization matrix.
     *
     * @param {Array} amortizationMatrix - The amortization matrix to generate data from.
     * @param {number} barChartLength - The desired length of the bar chart.
     * @returns {Array} - The generated data for the bar chart.
     */
    const generateData = (amortizationMatrix, barChartLength) => {
        const segmentSize = Math.floor(amortizationMatrix.length / barChartLength);
        const barChartLengthAdjusted = Math.ceil(amortizationMatrix.length / segmentSize);
        return Array.from(
            {length: barChartLengthAdjusted},
            (_, i) => {
                const start = i * segmentSize;
                const endCandidate = (i + 1) * segmentSize;
                const end = endCandidate >= amortizationMatrix.length ? amortizationMatrix.length : endCandidate;
                const segment = amortizationMatrix.slice(start, end);
                return summarizeSegment(segment, end);
            }
        );
    }

    /**
     * Calculates the data for a bar chart based on the given amortization matrix.
     * If the length of the amortization matrix is less than or equal to the bar chart length,
     * the amortization matrix itself is used as the data for the bar chart.
     *
     * Otherwise, the data is generated directly using the related columns from the amortization matrix.
     *
     * @param {Array} amortizationMatrix - The array representing the amortization matrix.
     * @param {number} barChartLength - The desired length of the bar chart.
     * @returns {Array} - The calculated data for the bar chart.
     */
    const data = amortizationMatrix.length <= barChartLength
        ? amortizationMatrix.map(item => ({
            period: item.period,
            [ACC_AMORTIZATION]: item[ACC_AMORTIZATION],
            [ACC_INTEREST_RATE]: item[ACC_INTEREST_RATE],
            [ACC_BONDING]: item[ACC_BONDING]
        }))
        : generateData(amortizationMatrix, barChartLength);

    return (
        <Box id={"FrenchAmortizationCostChart"}>
            <Typography variant="h4"><Trans i18nKey={'graph_accumulated_cost_per_type'}/></Typography>
            <ResponsiveContainer width="100%" height={300}>
                <BarChart data={data}>
                    <CartesianGrid strokeDasharray="3 3"/>
                    <XAxis dataKey="period"/>
                    <YAxis hide={true}/>
                    <Tooltip/>
                    <Legend/>
                    <Bar name="Amortization" dataKey={ACC_AMORTIZATION} fill={AMORTIZATION_COLOR} stackId="a"/>
                    <Bar name="Interest Rate" dataKey={ACC_INTEREST_RATE} fill={INTEREST_RATE_COLOR}
                         stackId="a"/>
                    <Bar name="Bonding" dataKey={ACC_BONDING} fill={BONDING_COLOR} stackId="a"/>
                </BarChart>
            </ResponsiveContainer>
            <Typography>
                <Trans i18nKey={'graph_accumulated_cost_per_type_help_text'} components={{br: <br/>}}/>
            </Typography>
        </Box>
    );
}