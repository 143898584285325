export const PERIOD = 'period';
export const INTEREST_RATE = 'interest_rate';
export const MONTHLY_PAYMENT = 'monthly_payment';
export const PERIOD_AMORTIZATION = 'period_amortization';
export const ACC_AMORTIZATION = 'accumulated_amortization';
export const PERIOD_AMORTIZED = 'period_amortized';
export const PERIOD_INTEREST_RATE = 'period_interest_rate';
export const ACC_INTEREST_RATE = 'accumulated_interest_rate';
export const BONDING_COST = 'bonding_cost';
export const BONDING_BONUS = 'bonding_bonus';
export const ACC_BONDING = 'accumulated_bonding';
export const OUTSTANDING_PRINCIPAL = 'outstanding_principal';