import React from 'react';
import {sliderModifier} from "../../util/SliderModifier";
import {Box, FormControl, FormHelperText, Typography} from "@mui/material";
import Grid from "@mui/material/Grid2";
import Slider from "@mui/material/Slider";

/**
 * Creates a common slider component.
 *
 * @param {Object} options - The configuration options for the slider.
 * @param {string} options.title - The title of the slider.
 * @param {number} options.value - The initial value of the slider.
 * @param {function} options.onChange - The callback function to call when the slider value changes.
 * @param {number} [options.min=0] - The minimum value of the slider.
 * @param {number} options.max - The maximum value of the slider.
 * @param {number} [options.step=1] - The step value of the slider.
 * @return {ReactElement} The rendered common slider component.
 */
export default function CommonSlider(
    {
        title, value, onChange, helpText, min = 0, max, step = 1
    }
) {

    return (
        <Box sx={{...sliderModifier, height: 'fit-content'}}>
            <FormControl fullWidth>
                <Box className="composed-slider"
                     sx={{
                         height: {xs: 'fit-content !important', mi: 'fit-content !important'},
                         padding: {xs: '0 1.5em !important', mi: '0 1em !important'}
                     }}>
                    <Typography gutterBottom variant={"body2"}
                                sx={{
                                    color: "#fff",
                                    fontSize: {xs: '1.2em !important', mi: '0.7em !important'},
                                    marginBottom: {xs: '0.8em !important', mi: '0.5em !important'},
                                }}>
                        {title}
                    </Typography>
                    <Grid container columns={20} spacing={1} alignItems={'center'}>
                        <Grid item size={1} sx={{height: '100%', textAlign: 'center'}}>
                            <Typography className={"value-slider"}
                                        sx={{fontSize: {xs: '2em !important', mi: '1em !important'}}}>
                                {value}
                            </Typography>
                        </Grid>
                        <Grid item size='grow'>
                            <Slider
                                className={"slider"}
                                value={value}
                                aria-describedby={`${value}_helper`}
                                min={min}
                                max={max}
                                step={step}
                                onChange={(event) => onChange(event)}
                                sx={{color: '#ffffff'}}
                            />
                        </Grid>
                    </Grid>
                </Box>
                {helpText &&
                    <FormHelperText id={`${value}_help`}
                                    style={{width: '100%', color: 'grey', margin: '0', padding: '0 0 0 1em'}}>
                        {helpText}
                    </FormHelperText>}
            </FormControl>
        </Box>
    )
        ;
}
    