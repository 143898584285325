import React from 'react'
import './MortgageOverview.css'
import {LinearProgress, Typography} from '@mui/material'
import Grid from '@mui/material/Grid2'
import {CommonChip} from '../../../CommonChip/CommonChip'
import {
    ACC_BONDING,
    ACC_INTEREST_RATE,
    MONTHLY_PAYMENT,
    PERIOD_INTEREST_RATE
} from '../../../../constant/AmortizationMatrix'
import {
    BONDING_COLOR,
    BONDING_COLOR_HOVER,
    INTEREST_RATE_COLOR,
    INTEREST_RATE_COLOR_HOVER,
    MONTHLY_PAYMENT_COLOR,
    MONTHLY_PAYMENT_COLOR_HOVER
} from '../../../../constant/Color'
import {
    LOAN_ASSET,
    LOAN_FIXED_YEARS,
    LOAN_PRINCIPAL,
    LOAN_RELATED_EXPENSES_PERCENTAGE
} from '../../../../constant/Mortgage'
import {Trans} from 'react-i18next'

const calculateAccumulatedInterestFixedPeriod = (formValues, amortizationMatrix) => {
    const months = parseInt(formValues[LOAN_FIXED_YEARS] * 12)
    if (isNaN(months) || months <= 0) return 0
    const fixedPeriods = amortizationMatrix.slice(0, months)
    return fixedPeriods.reduce((acc, curr) => acc + parseFloat(curr[PERIOD_INTEREST_RATE]) || 0, 0)
}

export default function MortgageOverview({formValues, amortizationMatrix}) {

    const firstPayment = parseFloat(amortizationMatrix[0][MONTHLY_PAYMENT])
    const highestPayment = parseFloat(Math.max(...amortizationMatrix.map(payment => payment[MONTHLY_PAYMENT])))
    const accumulatedInterest = parseFloat(amortizationMatrix[amortizationMatrix.length - 1][ACC_INTEREST_RATE])
    const accumulatedBonding = parseFloat(amortizationMatrix[amortizationMatrix.length - 1][ACC_BONDING])
    const relatedExpenses = (parseFloat(formValues[LOAN_RELATED_EXPENSES_PERCENTAGE]) / 100) * parseFloat(formValues[LOAN_ASSET])
    const accumulatedExpenses = accumulatedInterest + accumulatedBonding + relatedExpenses
    const totalMortgage = (parseFloat(formValues[LOAN_ASSET]) + accumulatedExpenses)
    const percentageInterestRate = ((accumulatedInterest / parseFloat(formValues[LOAN_PRINCIPAL])) * 100)
    const percentageLoanByAsset = ((parseFloat(formValues[LOAN_PRINCIPAL]) / parseFloat(formValues[LOAN_ASSET])) * 100)
    const accumulatedInterestFixedPeriod = calculateAccumulatedInterestFixedPeriod(formValues, amortizationMatrix)
    const accumulatedInterestFixedPeriodPercentage = (accumulatedInterestFixedPeriod > 0)
        ? (accumulatedInterestFixedPeriod / accumulatedInterest * 100)
        : 0.00
    const accumulatedInterestVariablePeriod = parseFloat(amortizationMatrix[amortizationMatrix.length - 1][ACC_INTEREST_RATE]) - accumulatedInterestFixedPeriod
    const accumulatedInterestVariablePeriodPercentage = 100.0 - parseFloat(accumulatedInterestFixedPeriodPercentage)

    const choosePercentageLoanByAssetColor = () => {
        if (percentageLoanByAsset >= 90) {
            return 'error'
        } else if (percentageLoanByAsset >= 80 || percentageLoanByAsset < 40) {
            return 'warning'
        } else {
            return 'success'
        }
    }

    const choosePercentageInterestRateColor = () => {
        if (percentageInterestRate >= 30) {
            return 'error'
        } else if (percentageInterestRate >= 15) {
            return 'warning'
        } else {
            return 'success'
        }
    }

    return (amortizationMatrix &&
        <Grid container id={'MortgageOverview'} direction='column' spacing={2}>
            <Grid item xs={12}>
                <Typography variant='h4' style={{marginBottom: '0.5em'}}><Trans i18nKey={'overview'}/></Typography>
                <Typography variant='h5'><Trans i18nKey={'overall_percentages'}/></Typography>
                <Typography variant='h6' style={{marginBottom: '0.5em'}}>
                    <Trans i18nKey={'overview_requested_loan_by_asset_value'}/>: &nbsp;
                    <span className={'percentage'}>{percentageLoanByAsset.toFixed(2)}%</span>
                </Typography>
                <LinearProgress
                    variant='determinate'
                    color={choosePercentageLoanByAssetColor()}
                    value={percentageLoanByAsset.toFixed(2)}
                    style={{height: '1em', borderRadius: '1em', marginBottom: '1em'}}
                />
                <Typography variant='h6' style={{marginBottom: '0.5em'}}>
                    <Trans i18nKey={'overview_interest_rate_total_loan'}/>: &nbsp;
                    <span className={'percentage'}>{percentageInterestRate.toFixed(2)}%</span>
                </Typography>
                <LinearProgress
                    variant='determinate'
                    color={choosePercentageInterestRateColor()}
                    value={percentageInterestRate.toFixed(2)}
                    style={{height: '1em', borderRadius: '1em', marginBottom: '1em'}}
                />
                <Typography variant='h6' style={{marginBottom: '0.5em'}}>
                    <Trans i18nKey={'overview_accumulated_fixed_interest_fixed_period'} values={{
                        'accumulated_interest_rate': accumulatedInterestFixedPeriod.toFixed(2)
                    }}/>: &nbsp;
                    <span className={'percentage'}>{accumulatedInterestFixedPeriodPercentage.toFixed(2)}%</span>
                </Typography>
                <Typography variant='h6' style={{marginBottom: '0.5em'}}>
                    <Trans i18nKey={'overview_accumulated_variable_interest_fixed_period'} values={{
                        'accumulated_interest_rate': accumulatedInterestVariablePeriod.toFixed(2)
                    }}/>: &nbsp;
                    <span className={'percentage'}>{accumulatedInterestVariablePeriodPercentage.toFixed(2)}%</span>
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant='h5'><Trans i18nKey={'installments'}/></Typography>
                <CommonChip
                    text={<Trans i18nKey={'first_payment'}/>}
                    value={firstPayment.toFixed(2)}
                    color={MONTHLY_PAYMENT_COLOR}
                    colorHover={MONTHLY_PAYMENT_COLOR_HOVER}
                    link={'/financial-glossary/loan/installments'}
                />
                <CommonChip
                    text={<Trans i18nKey={'highest_payment'}/>}
                    value={highestPayment.toFixed(2)}
                    color={'#5157a6'}
                    colorHover={'#313567'}
                    link={'/financial-glossary/loan/installments'}
                />
            </Grid>
            <Grid item xs={12}>
                <Typography variant='h5'><Trans i18nKey={'general'}/></Typography>
                <CommonChip
                    text={<Trans i18nKey={'total'}/>}
                    value={totalMortgage.toFixed(2)}
                    color={'black'}
                    link={'/financial-glossary/mortgage'}
                />
                <CommonChip
                    text={<Trans i18nKey={'initial_contribution'}/>}
                    value={(parseFloat(formValues[LOAN_ASSET]) - parseFloat(formValues[LOAN_PRINCIPAL])).toFixed(2)}
                    color={'#2b2b2b'}
                    colorHover={'#5e5e5e'}
                />
                <CommonChip
                    text={<Trans i18nKey={'loan'}/>}
                    value={parseFloat(formValues[LOAN_PRINCIPAL]).toFixed(2)}
                    color={'#6c6c6c'}
                    colorHover={'#9a9a9a'}
                    link={'/financial-glossary/loan'}
                />
                <CommonChip
                    text={<Trans i18nKey={'accumulated_expenses'}/>}
                    value={accumulatedExpenses.toFixed(2)}
                    color={'#a16a6a'}
                    colorHover={'#dc6666'}
                    link={'/financial-glossary/mortgage'}
                />
            </Grid>
            <Grid item xs={12}>
                <Typography variant='h5'><Trans i18nKey={'expenses'}/></Typography>
                <CommonChip
                    text={<Trans i18nKey={'related_expenses'}/>}
                    value={relatedExpenses.toFixed(2)}
                    color={'#752711'}
                    colorHover={'#5b1e0d'}
                />
                <CommonChip
                    text={<Trans i18nKey={'accumulated_interest_rate'}/>}
                    value={accumulatedInterest.toFixed(2)}
                    color={INTEREST_RATE_COLOR}
                    colorHover={INTEREST_RATE_COLOR_HOVER}
                    link={'/financial-glossary/loan/interest-rate'}
                />
                {parseFloat(accumulatedBonding) !== 0.0 && <CommonChip
                    text={<Trans i18nKey={'accumulated_bonding'}/>}
                    value={accumulatedBonding.toFixed(2)}
                    color={BONDING_COLOR}
                    colorHover={BONDING_COLOR_HOVER}
                />
                }
            </Grid>
        </Grid>
    )
}