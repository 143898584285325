import React from "react";
import './FrenchAmortizationCostPercentageProgressionChart.css';
import {Bar, BarChart, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';
import {Box, Typography} from "@mui/material";
import {AMORTIZATION_COLOR, BONDING_COLOR, INTEREST_RATE_COLOR} from "../../../../constant/Color";
import {ACC_AMORTIZATION, ACC_BONDING, ACC_INTEREST_RATE} from "../../../../constant/AmortizationMatrix";
import {Trans} from "react-i18next";

export function FrenchAmortizationCostPercentageProgressionChart({amortizationMatrix}) {

    /**
     * The length of a bar in a bar chart.
     *
     * @type {number}
     */
    const barChartLength = 20;

    const interval = Math.ceil(amortizationMatrix.length / barChartLength);
    const data = [];

    for (let i = 0; i < amortizationMatrix.length; i += interval) {
        let amortizationSum = 0, interestRateSum = 0, bondingSum = 0;
        for (let j = i; j < i + interval && j < amortizationMatrix.length; j++) {
            amortizationSum += amortizationMatrix[j][ACC_AMORTIZATION];
            interestRateSum += amortizationMatrix[j][ACC_INTEREST_RATE];
            bondingSum += amortizationMatrix[j][ACC_BONDING];
        }

        let total = amortizationSum + interestRateSum + bondingSum;
        data.push({
            'date': amortizationMatrix[i].date,
            [ACC_AMORTIZATION]: (amortizationSum / total) * 100,
            [ACC_INTEREST_RATE]: (interestRateSum / total) * 100,
            [ACC_BONDING]: (bondingSum / total) * 100
        });
    }

    return (
        <Box id={"FrenchAmortizationCostPercentageProgressionChart"}>
            <Typography variant="h4"><Trans i18nKey={'graph_evolution_effort_per_type'}/></Typography>
            <ResponsiveContainer width="100%" height={300}>
                <BarChart data={data}>
                    <XAxis dataKey="date"/>
                    <YAxis domain={[0, 101]} hide={true}/>
                    <Tooltip/>
                    <Legend/>
                    <Bar dataKey={ACC_AMORTIZATION} name="Amortization" stackId="a" fill={AMORTIZATION_COLOR}/>
                    <Bar dataKey={ACC_INTEREST_RATE} name="Interest rate" stackId="a" fill={INTEREST_RATE_COLOR}/>
                    <Bar dataKey={ACC_BONDING} name="Bonding" stackId="a" fill={BONDING_COLOR}/>
                </BarChart>
            </ResponsiveContainer>
            <Typography>
                <Typography>
                    <Trans i18nKey={'graph_evolution_effort_per_type_help_text'} components={{br: <br/>}}/>
                </Typography>
            </Typography>
        </Box>
    );
}