import React from "react";
import {InputAdornment, Typography} from "@mui/material";

/**
 * Creates a common input adornment component that wraps
 * an InputAdornment component from the Material-UI library.
 *
 * @param {Object} props - The properties of the component.
 * @param {string} [props.position='end'] - The position of the adornment. Defaults to 'end'.
 * @param {string} props.value - The value to be displayed in the adornment.
 *
 * @returns {JSX.Element} - The rendered InputAdornment component.
 */
export default function CommonInputAdornment({position = 'end', value}) {

    return (
        <InputAdornment position={position}>
            <Typography style={{color: "#fff"}}>{value}</Typography>
        </InputAdornment>
    )
}