import React from "react";

import {AttachMoney, Build, Home, LibraryBooks, Store} from "@mui/icons-material";

export const MENU_ITEMS = [
    {label: 'home', icon: <Home fontSize='medium' sx={{color: 'wheat'}}/>, link: '/'},
    {label: 'loan', icon: <AttachMoney fontSize='medium' sx={{color: 'wheat'}}/>, link: '/financial-glossary/loan'},
    {label: 'mortgage', icon: <Store fontSize='medium' sx={{color: 'wheat'}}/>, link: '/financial-glossary/mortgage'},
    {label: 'glossary', icon: <LibraryBooks fontSize='medium' sx={{color: 'wheat'}}/>, link: '/financial-glossary'},
    {label: 'tools', icon: <Build fontSize='medium' sx={{color: 'wheat'}}/>, link: '/tools'}
]