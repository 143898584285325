import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

export const LNG_GENERAL = 'general';
export const LNG_SEO = 'seo';
export const LNG_FINANCIAL_GLOSSARY = 'financial_glossary';

export const supportedLngs = {
    en: "English",
    es: "Español"
};

await i18n
    .use(initReactI18next)
    .use(LanguageDetector)
    .use(Backend)
    .init({
        fallbackLng: 'en',
        supportedLngs: Object.keys(supportedLngs),
        debug: false,
        ns: [LNG_GENERAL, LNG_SEO, LNG_FINANCIAL_GLOSSARY],
        defaultNS: LNG_GENERAL,
        preload: [LNG_GENERAL, LNG_SEO],
        interpolation: {
            escapeValue: false,
        },
        backend: {
            loadPath: '/locales/{{ns}}/{{lng}}.json',
        },
        react: {useSuspense: true}
    });

export default i18n;
