import React, {useRef, useState} from 'react'
import './Header.css'
import {Link, useNavigate} from 'react-router-dom'
import {Box, Drawer, IconButton, List, ListItem, ListItemText, Typography} from '@mui/material'
import Grid from '@mui/material/Grid2'
import {OAuth2} from '../OAuth2/OAuth2'
import MenuIcon from "@mui/icons-material/Menu"
import CloseIcon from "@mui/icons-material/Close"
import {MENU_ITEMS} from "../../constant/MenuItems"
import {Trans} from "react-i18next"


export default function Header({isOpen, setIsOpen, isMobile}) {

    const arrowRef = useRef(null);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false)
    const navigate = useNavigate();

    const handleDrawerToggle = () => {
        setIsDrawerOpen(!isDrawerOpen)
    };

    const handleDrawerToggleAndNavigate = (link) => {
        setIsDrawerOpen(!isDrawerOpen)
        navigate(link)
    };

    const handleCollapse = () => {
        setIsOpen(!isOpen)
    }

    const drawerContent = (
        <div style={{color: 'white', backgroundColor: '#031823', width: '60vw', height: '100vh'}}>
            <IconButton onClick={handleDrawerToggle} style={{margin: '10px'}}>
                <CloseIcon sx={{color: 'wheat'}}/>
            </IconButton>
            <List>
                {MENU_ITEMS.map((item) => (
                    <ListItem button key={item.label}
                              style={{cursor: 'pointer'}}
                              onClick={() => handleDrawerToggleAndNavigate(item.link)}>
                        {item.icon}
                        <ListItemText primary={<Trans i18nKey={item.label}/>} sx={{marginLeft: '0.5em'}}/>
                    </ListItem>
                ))}
            </List>
        </div>
    );

    return (
        <Grid container id={'Header'} columns={12} spacing={{xs: 1, sm: 1, md: 1}} display={'flex'} sx={{
            width: '100vw',
            height: {xs: '7em', mi: '3.5em', md: '5em'},
            backgroundColor: 'rgb(0, 6, 7)',
            position: 'fixed',
            zIndex: '100'
        }}
        >
            <Grid item size='grow' display={'flex'} alignItems={'center'}>
                <Box
                    id={'arrow'}
                    ref={arrowRef}
                    component={'img'}
                    display={{xs: 'none', sm: 'none', md: 'block'}}
                    sx={{
                        position: 'relative',
                        bottom: '0.3em',
                        cursor: 'pointer',
                        transform: isOpen ? 'rotate(0deg)' : 'rotate(180deg)'
                    }}
                    onClick={handleCollapse}
                    alt={isOpen ? 'Close Sidebar' : 'Open Sidebar'}
                    src="/images/sidebar-arrow.png"
                />
                <Link to={'/'}
                      style={{textDecoration: 'none', padding: 0, margin: 0, display: 'flex', alignItems: 'center'}}
                >
                    <img src={'/images/logo.png'}
                         alt={'Financial Breaker Logo'}
                         style={{
                             width: '3em',
                             height: '3em',
                             marginLeft: '1em',
                             padding: '0.3em',
                             position: 'relative',
                             bottom: '0.2em'
                         }}
                    />
                    <Typography variant='p' id={'site-name'}>
                        {isMobile ? 'FB.COM' : 'FINANCIALBREAKER.COM'}
                    </Typography>
                </Link>
            </Grid>
            <Grid item size='auto' display='flex' alignItems='center' justifyContent='center'>
                <OAuth2 withLogo={false} withCheckAuthentication={true}/>
            </Grid>
            {isMobile &&
                <Grid item size='auto' display='flex' alignItems='center' justifyContent='center'
                      style={{marginRight: '1em'}}>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        onClick={handleDrawerToggle}
                    >
                        <MenuIcon/>
                    </IconButton>
                    <Drawer
                        anchor="left"
                        open={isDrawerOpen}
                        onClose={handleDrawerToggle}
                    >
                        {drawerContent}
                    </Drawer>
                </Grid>
            }
        </Grid>
    )
}