import React from 'react';
import {FormControl, FormHelperText, TextField} from "@mui/material";
import {Trans} from "react-i18next";
import {MUI_STYLES} from "../../constant/Component";
import {BONDING_COLOR_HOVER} from "../../constant/Color";

export default function CommonTextField(
    {
        formRegister, value, onChange, onBlur = (_) => {}, labelKey, helpText, error,
        disabled = false, type = "text", required = false,
        ...otherProps
    }
) {

    // Extracting the form properties into a variable
    const formProps = formRegister ? formRegister : {};

    return (
        <FormControl fullWidth>
            <TextField
                {...formProps}
                value={value}
                disabled={disabled}
                onChange={(event) => onChange(event)}
                onBlur={(event) => onBlur(event)}
                required={required}
                aria-describedby={`${value}_helper`}
                type={type}
                label={<Trans i18nKey={labelKey}/>}
                error={error}
                fullWidth
                margin="normal"
                sx={{
                    color: 'white',
                    ...MUI_STYLES,
                }}
                InputProps={{
                    style: {
                        color: disabled ? BONDING_COLOR_HOVER : 'white',
                        WebkitTextFillColor: disabled ? BONDING_COLOR_HOVER : 'white',
                        borderRadius: "1em"
                    }
                }}
                InputLabelProps={{
                    style: {color: 'white'},
                }}
                {...otherProps}
            />
            {helpText && <FormHelperText id={`${value}_help`} style={{color: (error) ? 'red' : 'grey'}}>
                {helpText}
            </FormHelperText>}
        </FormControl>
    );

}