import React, {useState} from 'react'
import './App.css'
import './App-media.css'
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom'
import Home from './page/Home/Home'
import Sidebar from './component/Sidebar/Sidebar'
import FrenchMortgageCalculator from './page/FrenchMortgageCalculator/FrenchMortgageCalculator'
import FinancialGlossaryTerm from './page/FinancialGlossaryTerm/FinancialGlossaryTerm'
import FinancialGlossary from './page/FinancialGlossary/FinancialGlossary'
import Tools from './page/Tool/Tools'
import FrenchMortgageComparator from './page/FrenchMortgageComparative/FrenchMortgageComparator'
import {Container, CssBaseline} from '@mui/material'
import {createTheme, ThemeProvider} from '@mui/material/styles'
import Grid from '@mui/material/Grid2'
import ResponsiveNavBar from './component/ResponsiveNavBar/ResponsiveNavBar'

export default function App() {

    const customTheme = createTheme({
        breakpoints: {
            values: {
                xs: 0,
                sm: 600,
                mi: 769,
                md: 900,
                lg: 1200,
                xl: 1536
            }
        }
    })

    const [isOpen, setIsOpen] = useState(true)

    return (
        <ThemeProvider theme={customTheme}>
            <CssBaseline/>
            <Container id={'App'} disableGutters={true} maxWidth={false} style={{height: '100vh', minHeight: '100vh'}}>
                <Router>
                    <ResponsiveNavBar isOpen={isOpen} setIsOpen={setIsOpen}/>
                    <Grid container columns={14} spacing={0.1}>
                        <Grid item size={{xs: 0, md: isOpen ? 2 : 0}} style={{
                            height: '100vh',
                            minHeight: '100vh',
                            position: 'fixed'
                        }}>
                            <Sidebar isOpen={isOpen}/>
                        </Grid>
                        <Grid item id={'Main'} size='grow' offset={{xs: 0, md: isOpen ? 2 : 0}} style={{
                            height: '100vh',
                            minHeight: '100vh',
                            padding: '5em 1em 1em 1em'
                        }}>
                            <Routes>
                                <Route path='/' element={<Home/>}/>
                                <Route path='/financial-glossary/:page/:subpage?' element={<FinancialGlossaryTerm/>}/>
                                <Route path='/financial-glossary' element={<FinancialGlossary/>}/>
                                <Route path='/tool/french-mortgage-calculator' element={<FrenchMortgageCalculator/>}/>
                                <Route path='/tool/french-mortgage-comparator' element={<FrenchMortgageComparator/>}/>
                                <Route path='/tools' element={<Tools/>}/>
                            </Routes>
                        </Grid>
                    </Grid>
                </Router>
            </Container>
        </ThemeProvider>
    )
}
