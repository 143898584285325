import React from 'react';
import {FormControl, FormHelperText} from "@mui/material";
import {MUI_STYLES} from "../../constant/Component";
import Checkbox from "@mui/material/Checkbox";
import {Trans} from "react-i18next";

export default function CommonCheckbox(
    {
        formRegister, componentName, checked, onChange, labelKey, helpText, required = false, ...otherProps
    }
) {

    // Extracting the form properties into a variable
    const formProps = formRegister ? formRegister : {};

    return (
        <FormControl style={{display: "inline"}}>
            <span style={{paddingLeft: "1em"}}>{labelKey}</span>
            <Checkbox
                {...formProps}
                checked={checked}
                onChange={(event) => onChange(event)}
                required={required}
                aria-describedby={`${componentName}_helper`}
                margin="normal"
                sx={{
                    color: 'white',
                    ...MUI_STYLES
                }}
                InputProps={{
                    style: {
                        color: 'white',
                        borderRadius: "1em"
                    }
                }}
                InputLabelProps={{
                    style: {color: 'white'},
                }}
                {...otherProps}
            />
            {helpText &&
                <FormHelperText id={`${componentName}_help`} style={{color: 'grey'}}>
                    {helpText}
                </FormHelperText>
            }
        </FormControl>
    );

}