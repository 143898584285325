import React, {useEffect, useState} from 'react'
import './FinancialGlossary.css'
import {Typography} from '@mui/material'
import Grid from '@mui/material/Grid2'
import {Trans, useTranslation} from 'react-i18next'
import {Link} from 'react-router-dom'
import axios from 'axios'
import config from '../../config'
import {metaSEO} from '../../util/SEOTools'
import {LNG_FINANCIAL_GLOSSARY, LNG_SEO} from '../../i18n'

export default function FinancialGlossary() {

    const {t: i18n} = useTranslation(LNG_SEO)

    const language = i18n?.language?.split('-')[0] || 'en'
    const [terms, setTerns] = useState({})

    useEffect(() => {
        axios.get(`${config.apiUrl}/api/v1/content/financial-glossary/${language}`, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            if (response.status === 200) {
                setTerns(response.data)
            }
        }).catch(err => console.log(err))
    }, [])

    return (
        <Grid container id={'FinancialGlossary'}>
            {metaSEO(i18n, 'financial_glossary')}
            <Grid item size={12}>
                <Typography variant='h1' className={'page'}>
                    <Trans i18nKey={'financial_glossary'}/>
                </Typography>
            </Grid>
            <hr style={{width: '100%', margin: '2em 0'}}/>
            <Grid item size={12}>
                <ul style={{marginTop: 0}}>
                    {terms['_'] && terms['_']
                        .sort((term, candidate) => term.localeCompare(candidate))
                        .map(mainTerm => (
                            <li key={`financial-glossary-main-term-${mainTerm}`}>
                                <Link to={`/financial-glossary/${mainTerm}`}>
                                    <Typography variant='h2' className={'main'}>
                                        <Trans i18nKey={`${mainTerm.toLowerCase()}`}/>
                                    </Typography>
                                </Link>
                                <ul>
                                    {
                                        terms[mainTerm]?.sort((a, b) => a.localeCompare(b)).map((term, termIndex) => (
                                            <li key={`financial-glossary-${mainTerm}-${termIndex}`}>
                                                <Link to={`/financial-glossary/${mainTerm}/${term}`}>
                                                    <Typography variant='h3' className={'main'}>
                                                        <Trans
                                                            i18nKey={`${term.toLowerCase().replace(/-/g, '_')}`}
                                                            ns={LNG_FINANCIAL_GLOSSARY}
                                                        />
                                                    </Typography>
                                                </Link>
                                            </li>
                                        ))
                                    }
                                </ul>
                            </li>
                        ))
                    }
                </ul>
            </Grid>
        </Grid>
    )
}