import React, {useState} from 'react';
import './FrenchAmortizationMatrix.css';
import {
    Checkbox,
    FormControlLabel,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import {
    BONDING_BONUS,
    BONDING_COST,
    INTEREST_RATE,
    MONTHLY_PAYMENT,
    OUTSTANDING_PRINCIPAL,
    PERIOD,
    PERIOD_AMORTIZATION,
    PERIOD_AMORTIZED,
    PERIOD_INTEREST_RATE
} from "../../../constant/AmortizationMatrix";
import {
    AMORTIZATION_COLOR,
    BONDING_COLOR,
    INTEREST_RATE_COLOR,
    MONTHLY_PAYMENT_COLOR,
    PERIOD_COLOR
} from "../../../constant/Color";
import {Trans} from "react-i18next";

export default function FrenchAmortizationMatrix({amortizationMatrix}) {

    const [extended, setExtended] = useState(false)
    const showBondingCost = amortizationMatrix && amortizationMatrix.some(row => row[BONDING_COST] > 0)
    const showBondingBonus = amortizationMatrix && amortizationMatrix.some(row => row[BONDING_BONUS] > 0)
    const showPeriodAmortized = amortizationMatrix && amortizationMatrix.some(row => row[PERIOD_AMORTIZED] > 0)

    return (
        <Grid container id={"FrenchMortgageAmortizationMatrix"}>
            {amortizationMatrix &&
                <Grid id={"table"} item size={12}>
                    <Typography variant={"h3"} align="center">
                        <Trans i18nKey="amortization_matrix"/>
                    </Typography>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={extended}
                                onChange={event => setExtended(event.target.checked)}
                                name='extended'
                            />
                        }
                        label={<Trans i18nKey={'show_extended'}/>}
                        sx={{color: 'white'}}
                    />
                    <TableContainer id={"matrix"} component={Paper}>
                        <Table size="small">
                            <TableHead>
                                <TableRow class={'head'}>
                                    <TableCell><Trans i18nKey={'period'}/></TableCell>
                                    <TableCell><Trans i18nKey={'interest_rate'}/></TableCell>
                                    {showBondingCost ? <TableCell><Trans i18nKey={'bonding_cost'}/></TableCell> : null}
                                    {showBondingBonus ?
                                        <TableCell><Trans i18nKey={'bonding_bonus'}/></TableCell> : null}
                                    <TableCell><Trans i18nKey={'monthly_payment'}/></TableCell>
                                    <TableCell><Trans i18nKey={'period'}/> <Trans
                                        i18nKey={'interest_rate'}/></TableCell>
                                    <TableCell><Trans i18nKey={'period'}/> <Trans i18nKey={'amortization'}/></TableCell>
                                    {showPeriodAmortized ? <TableCell><Trans i18nKey={'period'}/> <Trans
                                        i18nKey={'amortized'}/></TableCell> : null}
                                    <TableCell><Trans i18nKey={'outstanding_principal'}/></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {amortizationMatrix.map((row, index) => {
                                        if (!extended && (index > 5 && index < (amortizationMatrix.length - 5))) return;
                                        if (!extended && index === 5) return (
                                            <TableRow key={`amortization-matrix-${row[PERIOD]}`}>
                                                <TableCell colSpan={12} style={{textAlign: 'center'}}>
                                                    = = = = = = = = = = = = = = = = = = = = = = = =
                                                </TableCell>
                                            </TableRow>
                                        );
                                        return (
                                            <TableRow key={`amortization-matrix-${row[PERIOD]}`}>
                                                <TableCell style={{color: PERIOD_COLOR, fontWeight: "bold"}}>
                                                    {row[PERIOD]}
                                                </TableCell>
                                                <TableCell>{parseFloat(row[INTEREST_RATE]).toFixed(4)}</TableCell>
                                                {showBondingCost
                                                    ? <TableCell style={{color: BONDING_COLOR, fontWeight: "bold"}}>
                                                        {parseFloat(row[BONDING_COST]).toFixed(2)}
                                                    </TableCell>
                                                    : null
                                                }
                                                {showBondingBonus ?
                                                    <TableCell>{parseFloat(row[BONDING_BONUS]).toFixed(2)}</TableCell> : null}
                                                <TableCell style={{color: MONTHLY_PAYMENT_COLOR, fontWeight: "bold"}}>
                                                    {parseFloat(row[MONTHLY_PAYMENT]).toFixed(2)}
                                                </TableCell>
                                                <TableCell style={{color: INTEREST_RATE_COLOR, fontWeight: "bold"}}>
                                                    {parseFloat(row[PERIOD_INTEREST_RATE]).toFixed(2)}
                                                </TableCell>
                                                <TableCell style={{color: AMORTIZATION_COLOR, fontWeight: "bold"}}>
                                                    {parseFloat(row[PERIOD_AMORTIZATION]).toFixed(2)}
                                                </TableCell>
                                                {showPeriodAmortized
                                                    ? <TableCell>{parseFloat(row[PERIOD_AMORTIZED]).toFixed(2)}</TableCell>
                                                    : null
                                                }
                                                <TableCell style={{fontWeight: "bold"}}>
                                                    {parseFloat(row[OUTSTANDING_PRINCIPAL]).toFixed(2)}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    }
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            }
        </Grid>
    )
}