import React, {useEffect, useState} from 'react';
import './AmortizationFormFrenchMortgageCalculator.css';
import {MenuItem, Typography} from '@mui/material';
import Grid from '@mui/material/Grid2';
import {Trans} from 'react-i18next';
import {
    AMORTIZATION_MONTHLY_PAYMENT_THRESHOLD_FROM_QUOTA_TO_PERIOD,
    AMORTIZATION_QUANTITY,
    AMORTIZATION_RATE_FREQUENCY,
    AMORTIZATION_TYPE,
    AMORTIZATION_TYPES,
    FREQUENCY
} from '../../../../constant/Mortgage';
import {GoogleOAuthProvider} from '@react-oauth/google';
import config from '../../../../config';
import GoogleLogin from '../../../GoogleLogin/GoogleLogin';
import CommonTextField from '../../../CommonTextField/CommonTextField';
import CommonSelect from '../../../CommonSelect/CommonSelect';
import FormValidationManager from '../../../../util/FormValidationManager';
import {
    VALIDATE_AMORTIZATION_MONTHLY_PAYMENT_THRESHOLD_FROM_QUOTA_TO_PERIOD,
    VALIDATE_AMORTIZATION_QUANTITY
} from './AmortizationFormFrenchMortgageCalculatorFormValidators';

export default function AmortizationFormFrenchMortgageCalculator(
    {formRegister, formValues, setFormValues, userLogged}
) {

    const setFormValue = (key, value) => {
        setFormValues({...formValues, [key]: value});
    };

    const [errors, setErrors] = useState(new Map())
    const formValidationManager = new FormValidationManager(errors, setErrors)

    return (
        <Grid container id={'AmortizationFormFrenchMortgageCalculator'}>
            <Grid item size={12}>
                <Typography className={'informative-form-paragraph'}>
                    <Trans i18nKey='amortization_form_advice' components={{1: <br/>}}/>
                </Typography>
            </Grid>
            {userLogged ? (
                <Grid container className={'frame'} direction={{sm: 'row', md: 'row'}}>
                    <Grid item className={'form-column'} size={{xs: 12, md: 6}}>
                        <CommonTextField
                            formRegister={formRegister(AMORTIZATION_QUANTITY)}
                            id={AMORTIZATION_QUANTITY}
                            value={formValues[AMORTIZATION_QUANTITY]}
                            label={<Trans i18nKey={'amortization_quantity'}/>}
                            onChange={(event) => {
                                formValidationManager.validateErrors(AMORTIZATION_QUANTITY, event.target.value, [VALIDATE_AMORTIZATION_QUANTITY]);
                                setFormValue(AMORTIZATION_QUANTITY, event.target.value)
                            }}
                            error={errors[AMORTIZATION_QUANTITY]}
                            helpText={
                                (errors[AMORTIZATION_QUANTITY])
                                    ? formValidationManager.getErrors(AMORTIZATION_QUANTITY)
                                    : <Trans i18nKey='amortization_quantity_help_text'/>
                            }
                            required={true}
                            type={'number'}
                            labelKey={AMORTIZATION_QUANTITY}
                        />
                        <CommonSelect
                            formRegister={formRegister(AMORTIZATION_RATE_FREQUENCY)}
                            value={formValues[AMORTIZATION_RATE_FREQUENCY]}
                            onChange={(event) => setFormValue(AMORTIZATION_RATE_FREQUENCY, event.target.value)}
                            menuItems={
                                () => FREQUENCY.map((freq, index) => (
                                    <MenuItem key={`list-amortization-rate-frequency-${index}`} value={freq}>
                                        <Trans i18nKey={`frequency_${freq}`}/>
                                    </MenuItem>
                                ))
                            }
                            helpText={<Trans i18nKey='amortization_rate_frequency'/>}
                        />
                        <CommonSelect
                            formRegister={formRegister(AMORTIZATION_TYPE)}
                            id={AMORTIZATION_TYPE}
                            value={formValues[AMORTIZATION_TYPE]}
                            label={<Trans i18nKey={'amortization_type_help_text'}/>}
                            onChange={(event) => setFormValue(AMORTIZATION_TYPE, event.target.value)}
                            menuItems={
                                () => AMORTIZATION_TYPES.map((type, index) => (
                                    <MenuItem key={`list-amortization-type-${index}`} value={type}>
                                        <Trans i18nKey={`${AMORTIZATION_TYPE}_${type}`}/>
                                    </MenuItem>
                                ))
                            }
                            helpText={<Trans i18nKey='amortization_type_help_text'/>}
                        />
                        {(formValues[AMORTIZATION_TYPE] === AMORTIZATION_TYPES[0]) &&
                            <CommonTextField
                                formRegister={formRegister(AMORTIZATION_MONTHLY_PAYMENT_THRESHOLD_FROM_QUOTA_TO_PERIOD)}
                                value={formValues[AMORTIZATION_MONTHLY_PAYMENT_THRESHOLD_FROM_QUOTA_TO_PERIOD]}
                                onChange={(event) => {
                                    formValidationManager.validateErrors(AMORTIZATION_MONTHLY_PAYMENT_THRESHOLD_FROM_QUOTA_TO_PERIOD, event.target.value, [VALIDATE_AMORTIZATION_MONTHLY_PAYMENT_THRESHOLD_FROM_QUOTA_TO_PERIOD]);
                                    setFormValue(AMORTIZATION_MONTHLY_PAYMENT_THRESHOLD_FROM_QUOTA_TO_PERIOD, event.target.value)
                                }}
                                error={errors[AMORTIZATION_MONTHLY_PAYMENT_THRESHOLD_FROM_QUOTA_TO_PERIOD]}
                                helpText={
                                    (errors[AMORTIZATION_MONTHLY_PAYMENT_THRESHOLD_FROM_QUOTA_TO_PERIOD])
                                        ? formValidationManager.getErrors(AMORTIZATION_MONTHLY_PAYMENT_THRESHOLD_FROM_QUOTA_TO_PERIOD)
                                        :
                                        <Trans i18nKey='amortization_type_period_monthly_payment_threshold_help_text'/>
                                }
                                required={true}
                                type={'number'}
                                step={'0.1'}
                                labelKey={AMORTIZATION_MONTHLY_PAYMENT_THRESHOLD_FROM_QUOTA_TO_PERIOD}
                            />
                        }
                    </Grid>
                </Grid>
            ) : (
                <Grid item style={{textAlign: 'center'}} size={12}>
                    <GoogleOAuthProvider clientId={config.googleId}>
                        <GoogleLogin/>
                    </GoogleOAuthProvider>
                </Grid>
            )}
        </Grid>
    )
}
