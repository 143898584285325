import React from 'react';
import './Sidebar.css';
import {Box, Typography} from '@mui/material';
import {Link} from "react-router-dom";
import {OAuth2} from "../OAuth2/OAuth2";
import {Trans} from "react-i18next";
import {MENU_ITEMS} from "../../constant/MenuItems";

export default function Sidebar({isOpen}) {

    return (
        <Box id={"Sidebar"}
             display={{xs: 'none', md: 'block'}}
             sx={{
                 width: '100%',
                 height: '100vh',
                 minHeight: '100vh',
                 textAlign: 'center',
                 marginTop: "6em",
                 zIndex: 100
             }}
        >
            <Typography variant="h5" component="div"
                        style={{
                            fontSize: "1em",
                            position: "absolute",
                            writingMode: "vertical-lr",
                            bottom: 0,
                            left: 0,
                            marginLeft: "0.2em",
                            marginBottom: "0.5em",
                            transform: "rotate(180deg)",
                            opacity: "30%",
                            letterSpacing: "1em"
                        }}>
                <Link to={"/"}>FinancialBreaker.com</Link>
            </Typography>
            <Box className={"content"}
                 sx={{
                     display: isOpen ? 'block' : 'none',
                     height: '100vh',
                     textAlign: 'left',
                     fontSize: {sm: '0.7em', md: '0.8em', xl: '1em'}
                 }}>
                <ul className={"menu"} style={{overflowX: 'hidden'}}>
                    {MENU_ITEMS.map((item) => (
                        <Link to={item.link} style={{display: 'flex', alignItems: 'center'}}>
                            {item.icon}
                            <span className={"menu-text"}><Trans i18nKey={item.label}/></span>
                        </Link>
                    ))}
                </ul>
                <div id={"Login"}
                     style={{
                         width: "100%",
                         paddingBottom: "2em",
                         position: 'absolute',
                         right: '0',
                         bottom: '0'
                     }}>
                    <OAuth2/>
                </div>
            </Box>
        </Box>
    )
}