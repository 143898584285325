import React from 'react';
import './GoogleLogin.css';
import './GoogleLogin-media.css';
import {useGoogleLogin} from "@react-oauth/google";
import {Button} from "@mui/material";
import GoogleIcon from "@mui/icons-material/Google";
import axios from "axios";
import config from "../../config";
import {USER_LOGIN} from "../../constant/EventBus";
import {PubSub} from "../../config/EventBus";
import {SECONDARY_ITEMS} from "../../constant/Color";
import {Trans} from "react-i18next";

export default function GoogleLogin() {

    const handleGoogleSuccess = async (response) => {
        const token = response['access_token'];
        axios({
            method: 'post',
            url: `${config.apiUrl}/api/v1/authentication/google/callback`,
            data: {"token": token},
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            },
        }).then(response => {
            PubSub.emit(USER_LOGIN, response.data);
        }).catch(response => console.log(response));
    };

    const login = useGoogleLogin({
        scope: 'email openid profile https://www.googleapis.com/auth/user.birthday.read https://www.googleapis.com/auth/user.gender.read',
        onSuccess: response => handleGoogleSuccess(response),
        onError: response => console.error(response)
    });

    return (
        <Button id={"GoogleLogin"} variant="contained" type="submit" style={{backgroundColor: SECONDARY_ITEMS}}
                onClick={() => login()}>
            <GoogleIcon className={"google-icon"}/> <span className={"login"}><Trans i18nKey={'login'}/></span>
        </Button>
    );
}