import axios from "axios";
import config from "../config";
import {PubSub} from "../config/EventBus";
import {USER_LOGIN} from "../constant/EventBus";


/**
 * Checks if the user is authenticated by making a GET request to the '/user/me' endpoint.
 * If the request is successful, it emits the USER_LOGIN event with the response data.
 * If the request fails, it logs the reason for the failure.
 * @async
 * @returns {void}
 */
export const checkAuthentication = async (callable = (_) => {}) => {
    axios({
        method: 'get',
        url: `${config.apiUrl}/api/v1/user/me`,
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
        },
    }).then(response => {
        if (response.status === 200) {
            callable(response.data);
            PubSub.emit(USER_LOGIN, response.data)
        }
    }).catch(reason => console.log(reason));
};