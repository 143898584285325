import React, {useEffect, useState} from 'react';
import './OAuth2.css';
import {GoogleOAuthProvider} from '@react-oauth/google';
import axios from 'axios';
import config from '../../config';
import {Box, Button, Container} from '@mui/material';
import GoogleLogin from '../GoogleLogin/GoogleLogin';
import Avatar from '@mui/material/Avatar';
import {USER_LOGIN} from '../../constant/EventBus';
import {PubSub} from '../../config/EventBus';
import {checkAuthentication} from '../../global/CheckAuthentication';
import {Trans} from 'react-i18next';

export const OAuth2 = ({withLogo = true, withCheckAuthentication = false}) => {

    const [user, setUser] = useState(undefined)

    const handleLogout = () => {
        axios.post(`${config.apiUrl}/api/v1/authentication/logout`, {}, {withCredentials: true})
            .then(response => PubSub.emit(USER_LOGIN, undefined))
            .catch(reason => console.log(reason))
    }

    const userEventHandler = user => {
        setUser(user)
    }

    useEffect(() => {
        if (withCheckAuthentication) {
            checkAuthentication()
        }
        PubSub.on(USER_LOGIN, userEventHandler)

        return () => {
            PubSub.off(USER_LOGIN, userEventHandler)
        };
    }, [])

    return (
        <Container id={'OAuth2'} sx={{height: '100%', display: 'flex', alignItems: 'center'}}>
            {user ? (
                <Box sx={{
                    width: '100%',
                    textAlign: 'center',
                    display: 'flex',
                    flexDirection: withLogo ? 'column' : 'row',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    {withLogo &&
                        <Avatar src={user.picture || 'images/generic_avatar.jpg'}
                                variant='circular'
                                sx={{
                                    width: '3em',
                                    height: '3em',
                                    border: '1px solid #ccc',
                                    margin: withLogo ? '0 0 0.5em 0' : '0 0 0 0'
                                }}
                        />
                    }
                    <h4 className={'username'}>{user.name}</h4>
                    <Button className={'logout'}
                            onClick={handleLogout}
                            sx={{marginLeft: withLogo ? '0' : '0.5em', justifyContent: 'center', alignItems: 'center'}}
                    >
                        <Trans i18nKey={'logout'}/>
                    </Button>
                </Box>
            ) : (
                <GoogleOAuthProvider clientId={config.googleId}>
                    <GoogleLogin/>
                </GoogleOAuthProvider>
            )}
        </Container>
    )
}