import React from 'react'
import {Typography} from "@mui/material";
import Grid from "@mui/material/Grid2";
import Abc from '@mui/icons-material/Abc';
import Insights from '@mui/icons-material/Insights';
import ThumbUp from '@mui/icons-material/ThumbUp';
import {Trans} from "react-i18next";

export default function MortgageBlock() {

    return (
        <Grid container>
            <Grid item size='auto' sx={{width: '100%'}}>
                <Typography className="title" variant="h4" gutterBottom>
                    <Trans i18nKey='block_manage_mortgages_title'/>
                </Typography>
                <Typography component={'h3'}
                            sx={{
                                color: 'wheat', fontWeight: 'bold', padding: '0 1em', textAlign: 'center'
                            }}>
                    <Trans i18nKey='block_manage_mortgages_summary'/>
                </Typography>
                <ul>
                    <li style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                        <Abc style={{fontSize: '1.5em', marginTop: '0.5em', marginBottom: '0.5em'}}/>
                        <Typography component="p">
                            <Trans i18nKey={'block_manage_mortgages_01'}
                                   components={{strong: <strong style={{color: 'wheat'}}/>}}
                            />
                        </Typography>
                    </li>
                    <li style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                        <Insights style={{fontSize: '1.5em', marginTop: '0.5em', marginBottom: '0.5em'}}/>
                        <Typography component="p">
                            <Trans i18nKey={'block_manage_mortgages_02'}
                                   components={{strong: <strong style={{color: 'wheat'}}/>}}
                            />
                        </Typography>
                    </li>
                    <li style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                        <ThumbUp style={{fontSize: '1.5em', marginTop: '0.5em', marginBottom: '0.5em'}}/>
                        <Typography component="p">
                            <Trans i18nKey={'block_manage_mortgages_03'}
                                   components={{strong: <strong style={{color: 'wheat'}}/>}}
                            />
                        </Typography>
                    </li>
                </ul>
                <Typography component="p" style={{
                    color: 'wheat',
                    fontWeight: 'bold',
                    fontSize: '2em',
                    textAlign: 'center',
                    padding: '0 1em 1em 0',
                }}>
                    <Trans i18nKey='block_manage_mortgages_try_it'/>
                </Typography>
            </Grid>
        </Grid>
    )
}